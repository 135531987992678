import React, { useEffect } from "react";
import Login from "./Login";
import YpLogin from "./tenyp_login";
import HomeYP from "./home_yp";
import Home from "./Home";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import Raise_Request from "./raise_request";

const CheckLogin: React.FC = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = React.useState(false);

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const tokenResponse = await instance.acquireTokenSilent(loginRequest);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/role/getRole?request_route=10YP`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${tokenResponse.accessToken}` },
        }
      );

      if (response.ok) {
        setLoading(false);
        const data = await response.json();
        localStorage.setItem("accesstoken", tokenResponse.accessToken);
        localStorage.setItem("roles", data.role);
        localStorage.setItem("zones", data.zones);
        localStorage.setItem("homeRoute", data.routes);
        localStorage.setItem("roleToken", data.role_token);
        localStorage.setItem("userObj", JSON.stringify(data));
        return true;
      } else if (response.status === 401) {
        toast.error(
          "You don't have a access to 10YP portal. Please contact admin.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setLoading(false);
        return 401;
      } else {
        toast.error("Internal server error. Please contact admin.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLoading(false);
        return false;
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal server error. Please contact admin.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response?.account) {
          instance.setActiveAccount(response.account); // Set active account
        } else {
          const accounts = instance.getAllAccounts();
          if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]); // Set active account if available
          }
        }
        setLoading(false);
        renderLoginComponent();
      })
      .catch((e) => {
        renderLoginComponent();
        console.error(e);
        toast.error("Login error", { theme: "dark" });
        setLoading(false);
      });
  }, [instance]);

  const [componentToRender, setComponentToRender] =
    React.useState<JSX.Element | null>(null);

  const renderLoginComponent = async () => {
    const baseUrl = window.location.hostname;
    const accounts = instance.getAllAccounts();
    if (accounts.length) {
      if (baseUrl.toLowerCase() === process.env.REACT_APP_DEV_10YP_HOSTNAME) {

        let result = await fetchRoles();
        if (result===401)
        {
          setComponentToRender(<Raise_Request />);
          
        }
        else if (result) {
          setComponentToRender(<HomeYP />);
        } else {
          setComponentToRender(<YpLogin />);
        }
      } else if (baseUrl === process.env.REACT_APP_DEV_PULSE_REPORT_HOSTNAME) {
        setComponentToRender(<Home />);
      }
    } else {
      if (baseUrl.toLowerCase() === process.env.REACT_APP_DEV_10YP_HOSTNAME) {
        setComponentToRender(<YpLogin />);
      } else if (baseUrl === process.env.REACT_APP_DEV_PULSE_REPORT_HOSTNAME) {
        setComponentToRender(<Login />);
      }
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      {componentToRender}
    </>
  );
};

export default CheckLogin;
