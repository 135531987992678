import "./App.css";
import "./pages/home/home_style.css";
import "./Responsive.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CheckLogin from "./pages/home/CheckLogin";
import RedirectToYPF from "./pages/home/RedirectToYPF";

function App() {
  return (
    <div className="App">
      <ToastContainer
        style={{ width: "auto" }}
        toastStyle={{
          fontSize: "var(--Info-14)",
          lineHeight: "1.5",
          padding: "15px",
        }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<CheckLogin />} />
           <Route path="/10yp" element={<RedirectToYPF />} />
          <Route path="/10_yp" element={<RedirectToYPF />} />  
          <Route path="*" element={<Navigate to={"/"}></Navigate>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
