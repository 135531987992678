import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { useLayoutEffect, useRef } from "react";

interface AreaChartYpProps {
  chartData2: any;
}

const AreaChartYp2: React.FC<AreaChartYpProps> = ({ chartData2 }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!chartRef.current) return;

    // Initialize root element
    const root = am5.Root.new(chartRef.current);

    // Apply themes
    root.setThemes([am5themes_Dark.new(root), am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        
      })
    );

    // Set background color
    chart.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 1,
      })
    );

    // Create X-Axis
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 50 });
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer,
      })
    );

    xAxis.children.push(
      am5.Label.new(root, {
        text: "Year",
        x: am5.p50,
        centerX: am5.p50,
        paddingTop: 10,
      })
    );

    xAxis.data.setAll([
      { year: "2015" },
      { year: "2016" },
      { year: "2017" },
      { year: "2018" },
      { year: "2019" },
      { year: "2020" },
      { year: "2021" },
      { year: "2022" },
      { year: "2023" },
      { year: "2024" },
    ]);

    xAxis.get("renderer")!.labels.template.setAll({
      rotation: 0,
      centerX: am5.p50,
      centerY: am5.p50,
    });
    // Create Y-Axis
    const yRenderer = am5xy.AxisRendererY.new(root, {});
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        extraMax: 0.1,
      })
    );

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Total PCC, L/pers",
        y: am5.p50,
        centerY: am5.p50,
        paddingRight: 10,
      })
    );

    yAxis.set("numberFormat", "#"); 

    // Create Area Series
    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: "Beer Volume",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "pcc",
        categoryXField: "year",
        fill: am5.color(0x0077b6),
        stroke: am5.color(0x0077b6),
        tooltip: am5.Tooltip.new(root, {
          labelText: "{categoryX}: {valueY}",
        }),
      })
    );

    // Apply Gradient Fill
    series.fills.template.setAll({
      fillOpacity: 0.5,
      visible: true,
      fillGradient: am5.LinearGradient.new(root, {
        stops: [
          { color: am5.color(0x0077b6), opacity: 0.6 },
          { color: am5.color(0x0077b6), opacity: 0.1 },
        ],
        rotation: Math.PI / 2,
      }),
    });

    // Remove AmCharts logo
    if (root._logo) {
      root._logo.dispose();
    }

    
    series.strokes.template.setAll({
      strokeWidth: 2,
    });

    // Cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none",
    }));
    cursor.lineY.set("visible", false); // Remove vertical line
    cursor.lineX.set("visible", false); // Remove horizontal line
    
    
    let tooltip = am5.Tooltip.new(root, {
      labelText: "[bold]{categoryX.formatNumber('#')}[/]: {valueY.formatNumber('#,###.0')} L/pers",
    });
    
    tooltip.get("background")!.setAll({
      fill: am5.color(0x333333), 
      fillOpacity: 0.9,
      stroke: am5.color(0xffffff),
      strokeWidth: 1,
      
    });
    
    tooltip.label.setAll({
      fill: am5.color(0xffffff),  
      fontSize: 14,
   
    });
    
    series.set("tooltip", tooltip);
    
    // Attach tooltip to cursor
    cursor.set("snapToSeries", [series]); 
    
    // Set Data
    series.data.setAll(chartData2);
    
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData2]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default AreaChartYp2;
