import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect, useRef } from "react";

interface ChartDataItem {
  year: number;
  "Core+"?: number;
  "Mainstream"?: number;
 "Premium / SP"?: number;
}

interface StackedBar2ChartProps {
    Stackedchart2: ChartDataItem[];
}

const StackedBarChart2: React.FC<StackedBar2ChartProps> = ({ Stackedchart2 = [] }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!chartRef.current || !Array.isArray(Stackedchart2) || Stackedchart2.length === 0) return;

    // Create new root
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 0,
        layout: root.verticalLayout
      })
    );

    // Remove AmCharts logo
    if (root._logo) {
      root._logo.dispose();
    }

    // Set background color to black
    chart.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 1,
      })
    );

    // Create X Axis
     let xRenderer = am5xy.AxisRendererX.new(root, {
         minorGridEnabled: true
       });
   
       let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
         categoryField: "year",
         renderer: xRenderer,
         tooltip: am5.Tooltip.new(root, {})
       }));
   
       xRenderer.grid.template.setAll({ location: 1 });
   
       xAxis.get("renderer").labels.template.setAll({
         fontSize: 14,
         fontWeight: "bold",
         fill: am5.color("#FFFFFF"),
         textAlign: "center",
         centerX: am5.p50,
         dy: 10
       });
   
       // Convert years to strings for x-axis
       const processedData = Stackedchart2.map(item => ({
         ...item,
         year: String(item.year)
       }));
       
             xAxis.get("renderer").labels.template.setAll({
               rotation: -90,
               centerY: am5.p50,
               centerX: am5.p50,
               paddingRight: 15,
              
             });
       xAxis.get("renderer").set("minGridDistance", 30);
       xAxis.data.setAll(processedData);

    // Create Y Axis
   let yAxis = chart.yAxes.push(
         am5xy.ValueAxis.new(root, {
          min:0,
           max: 100, // Ensuring Y-axis doesn't exceed 100
           strictMinMax: true, // Enforce the limits
           renderer: am5xy.AxisRendererY.new(root, {
             strokeOpacity: 0.1
           })
         })
       );
   
       yAxis.get("renderer").labels.template.setAll({
         fill: am5.color(0xffffff),
         fontSize: 14
       });
     yAxis.children.unshift(
          am5.Label.new(root, {
            rotation: -90,
            text: "Segment Weight, %",
            y: am5.p50,
            centerY: am5.p50,
            paddingRight: 15,
            fill: am5.color(0xffffff),
          })
        );
        yAxis.get("renderer").set("minGridDistance", 30);
    // Function to create series
    function makeSeries(name: string, color: string) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: name,
        categoryXField: "year"
      }));

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10),
        fill: am5.color(color),
        stroke: am5.color(color),
        maxWidth: 50
      });

      series.data.setAll(processedData);
      series.appear();

   
    }

    // Create Series with specific colors
    makeSeries("Mainstream", "#666666");
    makeSeries("Core+", "#FFD700");
    makeSeries("Premium / SP", "#98DFAF")

    return () => {
      root.dispose();
    };
  }, [Stackedchart2]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default StackedBarChart2;