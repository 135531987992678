import React from "react";
import "./loader.css";

export default function ComponentLoader() {
  return (
    <div className="componentLoader ">
      <div className="item">
        <i className="loader --7"></i>
      </div>
    </div>
  );
}
