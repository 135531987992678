import React, { useEffect } from "react";

interface DynamicHeadProps {
  title: string;
  favicon: string;
}

const DynamicHead: React.FC<DynamicHeadProps> = ({ title, favicon }) => {
  useEffect(() => {
    // Set title
    document.title = title;

    // Set favicon
    const link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = favicon;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, [title, favicon]);

  return null;
};

export default DynamicHead;
