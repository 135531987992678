import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface ChartDataItem {
  year: string;
  value?: number;
  org10_yp?: number;
  tenyp_25?: number;
  tenyp_24?: number;
}

interface ChartComponentProps {
  BeerRevochartData: ChartDataItem[];
}

const ChartComponent: React.FC<ChartComponentProps> = ({ BeerRevochartData = [] }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log("BarchartData:", BeerRevochartData);
    if (!chartRef.current || !Array.isArray(BeerRevochartData) || BeerRevochartData.length === 0) return;

    // Initialize root element
    let root = am5.Root.new(chartRef.current);

    // Apply theme
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    if (root._logo) {
      root._logo.dispose();
    }

    // Set background color
    chart.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 1,
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: am5xy.AxisRendererX.new(root, {}),
       
      })
    );
   xAxis.get("renderer").set("minGridDistance", 30);
 
    // Ensure labels are displayed correctly without commas
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xffffff), // White text
      fontSize: 14,
      text: "{category}" 
    });
      xAxis.get("renderer").labels.template.setAll({
          rotation: 0,
          centerY: am5.p0,
          centerX: am5.p0,
          paddingRight: 15
        });
    
        xAxis.get("renderer").grid.template.setAll({
          location:1
        })

    
    BeerRevochartData.forEach((data) => {
      data.year = String(data.year);
    });

  
    
    xAxis.get("renderer").grid.template.setAll({
      strokeOpacity: 0.2, // Adjust visibility
      stroke: am5.color(0x888888), // Grid color
      location: 1, 
    });
    
    xAxis.data.setAll(BeerRevochartData);

    // Create Y-Axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        
        renderer: am5xy.AxisRendererY.new(root, {}),
       
      })
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Volume, KHL",
        y: am5.p50,
        centerY: am5.p50,
        paddingRight: 10,
        fill: am5.color(0xffffff)
      
      })
    );
    

    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xffffff),
      fontSize: 14,
    });

    yAxis.get("renderer").setAll({
      minGridDistance: 50,
    });
    yAxis.get("renderer").grid.template.setAll({
      strokeOpacity: 0.2, // Adjust visibility
      stroke: am5.color(0x888888), // Grid color
    });
    

    // Function to create series
    function createSeries(field: string, name: string, color: number, dashed: boolean, bold: boolean) {
      let series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "year",
          stroke: am5.color(color),
          tooltip: am5.Tooltip.new(root, {
            labelText: "{categoryX}: {valueY}",
          }),
        })
      );
    
      series.strokes.template.setAll({
        strokeWidth: bold ? 4 : 2, 
        strokeDasharray: dashed ? [5, 3] : []
      });
    
      series.data.setAll(BeerRevochartData);
      return series;
    }
    
    // Add data series with correct colors and styles
    createSeries("value", "Actual", 0xffffff, false, false); 
    createSeries("tenyp_25", "10Y'25", 0xFFD700, false, true); 
    createSeries("tenyp_24", "10Y'24", 0xFFFF00, true, false); 
    

    // Add Cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    // Chart Animation
    chart.appear(1000, 100);

    // Cleanup on component unmount
    return () => {
      root.dispose();
    };
  }, [BeerRevochartData]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default ChartComponent;