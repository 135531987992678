import React, { useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// Define the Label interface
interface Label {
  name: string;
  field: string;
  color: string;
}

// Default labels with corresponding colors
const defaultLabels: Label[] = [
  { name: "Total Alcohol", field: "total", color: "#b88502" },
  { name: "Beer", field: "beer", color: "#7aa687" },
  { name: "Beer + Beyond Beer", field: "beerBeyondBeer", color: "#8f504c" },
  { name: "Wine", field: "wine", color: "#95927e" },
  { name: "Spirits", field: "spirits", color: "#917c21" },
];

// explicitly define props interface
export interface VolEvolutionProps {
  volevolchart: any[];
  labels?: Label[];
  defaultLabelIndex?: number;
}


const VolEvolution: React.FC<VolEvolutionProps> = ({
  volevolchart = [],
  labels = defaultLabels,
  defaultLabelIndex = 0,
}) => {
  const chartId = React.useId();
  const [selectedLabel, setSelectedLabel] = useState<Label>(
    labels[defaultLabelIndex] || labels[0]
  );
  const chartRef = React.useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      !chartRef.current ||
      !Array.isArray(volevolchart) ||
      volevolchart.length === 0
    )
      return;

    // Clear previous chart
    chartRef.current.innerHTML = "";

    // Initialize root element
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
      })
    );

    // Set background color
    chart.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 1,
      })
    );

    // Remove amCharts logo
    if (root._logo) {
      root._logo.dispose();
    }

    // X-Axis Renderer
    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 50 });
    xRenderer.labels.template.setAll({
      fill: am5.color("#ffffff"),
      fontSize: 14,
    });

    // Y-Axis Renderer
    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.setAll({
      fill: am5.color("#ffffff"),
      fontSize: 14,
    });

    // X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer,
        paddingLeft: 30,
      })
    );

    xAxis.children.push(
      am5.Label.new(root, {
        text: "Time (Years)",
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 14,
        fill: am5.color("#ffffff"),
      })
    );
    xAxis.get("renderer").labels.template.setAll({
      rotation: 0,
      centerY: am5.p50,
      centerX: am5.p50,
      paddingRight: 15
    });

    xAxis.get("renderer").grid.template.setAll({
      strokeOpacity: 0.2, // Adjust visibility
      stroke: am5.color(0x888888), // Grid color
      location: 1,
    });

    // Y-Axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
      })
    );


    yAxis.set(
      "numberFormatter",
      am5.NumberFormatter.new(root, {
        numberFormat: "#a",
        bigNumberPrefixes: [
          { number: 1e6, suffix: "M" },
          { number: 1e9, suffix: "B" },
        ],
      })
    );

    yAxis.children.unshift(
      am5.Label.new(root, {
        text: "Volume, KHL",
        rotation: -90,
        y: am5.p50,
        centerX: am5.p50,
        fontSize: 14,
        fill: am5.color("#ffffff"),
      })
    );
    yAxis.get("renderer").grid.template.setAll({
      strokeOpacity: 0.2, // Adjust visibility
      stroke: am5.color(0x888888), // Grid color
    });


    // Set data
    xAxis.data.setAll(volevolchart);

    // Create series
    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: selectedLabel.name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: selectedLabel.field,
        categoryXField: "year",
        stroke: am5.color(selectedLabel.color),
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{categoryX}[/]: {valueY.formatNumber('#.##')}",
        }),
      })
    );

    // Apply gradient fill for area chart
    const gradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(selectedLabel.color), opacity: 1 },
        { color: am5.color("#000000"), opacity: 1 },
      ],
    });

    series.fills.template.setAll({
      visible: true,
      fillGradient: gradient,
      fillOpacity: 0.5,
    });

    series.strokes.template.setAll({
      strokeWidth: 3, // Keep the line border visible
      stroke: am5.color(selectedLabel.color), // Use the dynamic color for the border
      strokeOpacity: 1, // Ensure full visibility
    });
    
    series.fills.template.setAll({
      visible: true,
      fillGradient: gradient,
      fillOpacity: 0.5, // Control the opacity of the fill
      strokeWidth: 0, // Remove the white border
      strokeOpacity: 0, // Ensure no stroke is applied to the fill
    });
    
    

    // Set data
    series.data.setAll(volevolchart);

    // Add cursor
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
        xAxis: xAxis,
      })
    );



    // Animate chart
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [selectedLabel, volevolchart, labels]);

  return (
    <div style={{ textAlign: "center" }}>
      <div ref={chartRef} style={{ width: "100%", height: "500px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "10px",
          flexWrap: "wrap",
        }}
      >
        {labels.map((label) => (
          <div
            key={label.field}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "4px",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => setSelectedLabel(label)}
          >
            <div
              style={{
                width: "16px",
                height: "16px",
                border: `2px solid ${label.color}`,
                borderRadius: "50%",
                marginRight: "8px",
                background:
                  selectedLabel.field === label.field
                    ? `radial-gradient(circle, ${label.color} 40%, transparent 60%)`
                    : "transparent",
                transition: "background 0.3s ease",
              }}
            />
            <span
              style={{
                fontWeight:
                  selectedLabel.field === label.field ? "bold" : "normal",
                color: "#ddd",
              }}
            >
              {label.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};


export default VolEvolution;
