import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect, useRef } from "react";

interface ChartDataItem {
  year: string;
  income: number;
}

interface BarsYPProps {
  BarchartData: ChartDataItem[];
}

const calculateCAGR = (start: number, end: number, years: number) => {
  return (((end / start) ** (1 / years) - 1) * 100).toFixed(1);
};

const BarsYP: React.FC<BarsYPProps> = ({ BarchartData = [] }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    console.log("BarchartData:", BarchartData);
    if (!chartRef.current || !Array.isArray(BarchartData) || BarchartData.length === 0) return;

    // Initialize root element
    let root = am5.Root.new(chartRef.current);

    // Apply theme
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    if (root._logo) {
      root._logo.dispose();
    }

    // Set background color
    chart.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 1,
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: am5xy.AxisRendererX.new(root, {})
      })
    );

    
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xffffff), 
      fontSize: 14,
      text: "{category}", 
    });

    xAxis.get("renderer").labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p50,
      paddingRight: 15
    });

    xAxis.get("renderer").grid.template.setAll({
      location:1
    })

    xAxis.get("renderer").set("minGridDistance", 30);
   
    BarchartData.forEach((data) => {
      data.year = String(data.year);
    });

    xAxis.data.setAll(BarchartData);

//Dynamic input 
let minValue = Math.min(...BarchartData.map(data => data.income));
let yAxis = chart.yAxes.push(
  am5xy.ValueAxis.new(root, {
    min: minValue*0.9,
    renderer: am5xy.AxisRendererY.new(root, {}),

  })
);

// Add "USD" label
yAxis.children.unshift(
  am5.Label.new(root, {
    rotation: -90,
    text: "USD",
    y: am5.p50,
    centerY: am5.p50,
    paddingRight: 15,
    fill: am5.color(0xffffff),
  })
);

// Format Y-axis labels with "$" symbol
yAxis.get("renderer")!.labels.template.setAll({
  fill: am5.color(0xffffff), 
  fontSize: 14,
});

// Ensure all Y-axis values have "$" formatting
yAxis.set("numberFormat", "'$'#,###");

// Set grid distance
yAxis.get("renderer")!.setAll({
  minGridDistance: 50,
});


    // Data bars
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Income",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "income",
        categoryXField: "year",
        clustered: false,
      })
    );

    series.columns.template.setAll({
      width: am5.percent(50),
      fill: am5.color(0xffd700),
      stroke: am5.color(0xdddddd),
      cornerRadiusTL: 6,
      cornerRadiusTR: 6,
     
    });

    series.columns.template.setAll({
      interactive: true,
      tooltipText: "[bold]{year}[/]: {valueY.formatNumber('#,###.0')}",
      tooltipPosition: "pointer",
    });

    series.columns.template.states.create("hover", {
      fill: am5.color(0xffa500),
    });

    // Set data
    xAxis.data.setAll(BarchartData);
    series.data.setAll(BarchartData);

    // Animate series
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [BarchartData]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default BarsYP;