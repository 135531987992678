import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useEffect } from "react";
 
interface DonutChartData {
  category: string;
  value: number;
}
interface DonutChartProps {
  year: string;
  totalAlcohol: number;
  data: DonutChartData[];
}
 
// Function to get colors dynamically
const getColor = (category: string) => {
  const colorMap: Record<string, am5.Color> = {
    "Beer": am5.color("#95cba5"),
    "Beyond Beer": am5.color("#c86d67"),
    "Spirits": am5.color("#e4c233"),
    "Wine": am5.color("#f9f3d3"),
  };
  return colorMap[category] || am5.color("#ccc"); 
};
 
const DonutChart = (componentProp:DonutChartProps) => {
  useEffect(() => {
    let root = am5.Root.new(`chartdiv-${componentProp.year}`);
    root.setThemes([am5themes_Animated.new(root)]);
 
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(80), // Reduce to make the donut thicker
        width: am5.percent(100), // Increase width to enlarge chart
        height: am5.percent(120), // Increase height
        marginTop: 10,  // More space between charts
        marginBottom: 30,  
      })
    );chart.set("y", am5.percent(-10)); 
    
    // Add padding to the root container to further increase spacing
    root.container.set("paddingTop", 20);
    root.container.set("paddingBottom", 30);
    
   
   
    if (root._logo) {
      root._logo.dispose();
    }
 
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
      })
    );
 
    series.labels.template.setAll({
      text: "{valuePercentTotal.formatNumber('0.0')}%", 
      textType: "circular",
      inside: false,
      radius: 15,
      fill: am5.color(0xffffff),
      fontSize: 12,
    });
   
 
    // Center Label (Year & Total Alcohol)
    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        text: `[bold]${componentProp.year}[/]\n${componentProp.totalAlcohol} mhl LBE`,
        textAlign: "center",
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        fontSize: 20,
        fill: am5.color(0xffffff),
      })
    );
 
    series.slices.template.adapters.add("fill", (fill, target) => {
      const category = (target.dataItem?.dataContext as DonutChartData)?.category;
      return category ? getColor(category) : fill;
    });
 
    series.slices.template.adapters.add("stroke", (stroke, target) => {
      const category = (target.dataItem?.dataContext as DonutChartData)?.category;
      return category ? getColor(category) : stroke;
    });
 
    series.data.setAll(componentProp.data);
    series.appear(1000, 100);
 
    return () => root.dispose(); // Cleanup chart on unmount
  }, [componentProp.year, componentProp.totalAlcohol, componentProp.data]);
 
  return <div id={`chartdiv-${componentProp.year}`} style={{ width: "400px", height: "400px" }}></div>;
};
 
const Legend = () => {
  const legendItems = [
    { category: "Beer", color: "#95cba5" },
    { category: "Beyond Beer", color: "#c86d67" },
    { category: "Spirits", color: "#e4c233" },
    { category: "Wine", color: "#f9f3d3" },
  ];

  return (
    <div style={{ 
      display: "flex", 
      justifyContent: "center", 
      gap: "20px", 
      marginTop: "20px", 
      padding: "10px", 
      backgroundColor: "#222", 
      borderRadius: "5px"
    }}>
      {legendItems.map((item) => (
        <div key={item.category} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div
            style={{
              width: "16px",
              height: "16px",
              backgroundColor: item.color,
              borderRadius: "50%",
            }}
          ></div>
          <span style={{ color: "#ffffff", fontSize: "14px" }}>{item.category}</span>
        </div>
      ))}
    </div>
  );
};

const MultiYearCharts = () => {
  const chartData = [
    {
      year: "2015",
      totalAlcohol: 215,
      data: [
        { category: "Beer", value: 69.6 },
        { category: "Beyond Beer", value: 1.5 },
        { category: "Spirits", value: 24.9 },
        { category: "Wine", value: 4.0 },
      ],
    },
    {
      year: "2024",
      totalAlcohol: 222,
      data: [
        { category: "Beer", value: 69.6 },
        { category: "Beyond Beer", value: 1.5 },
        { category: "Spirits", value: 24.9 },
        { category: "Wine", value: 6.5 },
      ],
    },
    {
      year: "2035",
      totalAlcohol: 222,
      data: [
        { category: "Beer", value: 69.6 },
        { category: "Beyond Beer", value: 1.5 },
        { category: "Spirits", value: 24.9 },
        { category: "Wine", value: 6.5 },
      ],
    },
  ];

  return (
    <div style={{ textAlign: "center", backgroundColor: "#000", padding: "40px" }}>
      {/* Single legend placed at the top */}
      <Legend />  

      {/* Spacing for better layout */}
      <div style={{ marginBottom: "30px" }}></div>

      {/* Three charts aligned horizontally */}
      <div style={{ display: "flex", justifyContent: "center", gap: "40px", flexWrap: "wrap" }}>
        {chartData.map((data) => (
          <DonutChart key={data.year} year={data.year} totalAlcohol={data.totalAlcohol} data={data.data} />
        ))}
      </div>
    </div>
  );
};

 
 
export default DonutChart;