import React, { useEffect, useState, useRef } from "react";
import AbLogo from "../../assets/images/abiLogo.png";
import { toast } from "react-toastify";
import DynamicHead from "../../components/DynamicHead";

export default function RaiseRequest() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    localStorage.setItem("loginSource", "10YP");
  }, []);

  const handleRaiseRequest = async () => {
    try {
      const access_token = localStorage.getItem("accesstoken") || "";
      if (!access_token) {
        toast.error("Authorization token is missing. Please log in again.");
        return;
      }

      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/raise-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
          },
          // body: JSON.stringify({ country: "failure" }),
        }

      );

      const data = await response.json();
      setIsLoading(false);
      if (response.ok) {
        setIsPopupOpen(true);
      } else {
        toast.error(data.detail || "Internal Server Error");
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <div>
      <DynamicHead title="10YP Raise Request" favicon="/logo-10.png" />
      <title>Raise Request</title>
      <div className="topLine"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="loginDivWrapper">
              <div className="loginDiv">
                <h2 className="mb-2">You do not have access to this page</h2>
                <p className="mb-4">
                  We’re sorry, your account does not have permission to access this site.
                </p>
                <button
                  className="loginButton"
                  id="RaiseRequestBtn"
                  onClick={handleRaiseRequest} // Directly call the function
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Processing..." : "Raise a Request"}
                </button>
                {isLoading && <div className="loader"></div>}

                <div className="bootomLine"></div>
              </div>
              <p>
                If you already have access, please <a href="/10yp"> log in.</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pageFooter">
        <div className="row">
          <div className="col-md-12">
            <img src={AbLogo} alt="AB InBev Logo" />
            <hr />
            <p>© 2024 Anheuser-Busch InBev All rights reserved.</p>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup" ref={popupRef}>
            <div className="loginDiv">
              <svg
                className="mb-3"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path
                  d="M16.9997 31.1663C24.8237 31.1663 31.1663 24.8237 31.1663 16.9997C31.1663 9.17564 24.8237 2.83301 16.9997 2.83301C9.17564 2.83301 2.83301 9.17564 2.83301 16.9997C2.83301 24.8237 9.17564 31.1663 16.9997 31.1663Z"
                  stroke="#13CB78"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 11.5L13.3125 23.5L8 18.0455"
                  stroke="#13CB78"
                  stroke-width="1.67647"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h2 className="mb-2">Successfully Sent ! </h2>
              <p className="mb-4">
                You have successfully sent an access request to the admin. The
                admin will review it and grant you the necessary permissions.
              </p>
            </div>
            {/* <button onClick={handlePopupToggle}>Close</button> */}
          </div>
        </div>
      )}

      <style>
        {`
          .popup-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .popup button {
            margin-top: 10px;
            padding: 5px 10px;
            border: none;
            background: #f7bb24;
            color: white;
            cursor: pointer;
            border-radius: 5px;
          }
        `}
      </style>
    </div>
  );
}

