import Header from "../../components/header/header";
import React, { useState, useEffect } from "react";

import Footer from "../../components/footer/footer";
import HomeTab from "../../components/homeTabs/homeTab";
import Loader from "../../components/loader/loader";
import DynamicHead from "../../components/DynamicHead";

export default function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {/* <Loader/> */}
      <Header />
      <DynamicHead title="Pulse Report" favicon="/favicon.png" />
      <div className="homeTop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="BannData text-center py-5">
                <h1>Performance Insights</h1>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="HomeTabs">
        <div className="container p-5-0 ">
          <div className="row">
            <div className="col-md-12">
              <HomeTab />
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
