import React from "react";
import "./loader.css";
import logo from "../../assets/images/siteLogo.svg";
import User1 from "../../assets/images/user1.png";

export default function Loader() {
  // setTimeout(function() {
  //   const loaderContainer = document.getElementsByClassName("loaderContainer")[0];
  //   if (loaderContainer) {
  //     loaderContainer.classList.add("hide-after-2s");
  //   }
  // }, 1000);

  return (
    <div className="loaderContainer ">
      <div className="item">
			  <i className="loader --7"></i>
      </div>
    </div>

  );
}
