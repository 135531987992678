import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import logo from "../../assets/images/siteLogo.svg";
import AbLogo from "../../assets/images/abiLogo.png";
import key from "../../assets/images/key.svg";
import DynamicHead from '../../components/DynamicHead'; 

const Login: React.FC = () => {
  
  const { instance } = useMsal();
  const handleLogin = (source: string) => {
    localStorage.setItem("loginSource", source); // Store the source of login
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error("Login error:", e);
    });
  };

  return (
    <>
      {/* <Loader/> */}
      <DynamicHead title="Pulse Report" favicon="/favicon.png" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="loginDivWrapper">
              <div className="loginDiv">
                <img src={logo} />
                <div className="line"></div>
                <h2 className="mb-2">Performance Insights</h2>
                <p className="mb-3">
                  Where Data-Driven Insights illuminate your path to informed
                  decisions.
                </p>
                <button
                  className="loginButton"
                  onClick={() => handleLogin("Pulse")}
                >
                  Login <img src={key} />
                </button>
                <div className="proOff">
                  <p className="m-0">A product of</p>
                  <img src={AbLogo} />
                </div>
                <div className="bootomLine"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
