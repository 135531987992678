
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { useLayoutEffect, useRef } from "react";

interface ChartDataItem {
  year: string;
  volume: number;
  pcc: number;
}

interface DualAxisChartProps {
  dualaxischartData: ChartDataItem[];
}

const DualAxisChart: React.FC<DualAxisChartProps> = ({ dualaxischartData }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!chartRef.current) return;

    let root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Dark.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingLeft: 10,
        paddingRight: 10,
         panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
      })
    );

    root.numberFormatter.set("numberFormat", "#,###");


    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: am5xy.AxisRendererX.new(root, {}),
        paddingLeft:20
      })
    );
    
     xAxis.get("renderer").labels.template.setAll({
                rotation: 0,
                centerY: am5.p0,
                centerX: am5.p0,
                paddingRight: 15
              });



    xAxis.get("renderer").grid.template.setAll({
      location:1
    })
    xAxis.get("renderer").set("minGridDistance", 40);
    xAxis.data.setAll(dualaxischartData);

   let yAxisLeft = chart.yAxes.push(
  am5xy.ValueAxis.new(root, {
    min:0,
    
    renderer: am5xy.AxisRendererY.new(root, {}),
  })
);



let yAxisRight = chart.yAxes.push(
  am5xy.ValueAxis.new(root, {
    min:0,
    paddingRight:10,

    renderer: am5xy.AxisRendererY.new(root, { opposite: true }),
  })
);

root.numberFormatter.set("numberFormat", "#,###");
    yAxisLeft.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Volume, KHL",
        y: am5.p50,
        centerY: am5.p50,
        paddingRight: 5,
        fill: am5.color(0xffffff),
      })
    );
    
    yAxisRight.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Total PCC, L/pers ",
        y: am5.p50,
        centerY: am5.p50,
        paddingLeft: 30,
        paddingTop:80,
        x:140,
        fill: am5.color(0xffffff),
      })
    );

   
let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
cursor.lineX.setAll({ visible: false });
cursor.lineY.setAll({ visible: false });

// Volume Series
let volumeSeries = chart.series.push(
  am5xy.SmoothedXLineSeries.new(root, {
    name: "Volume",
    xAxis: xAxis,
    yAxis: yAxisLeft,
    valueYField: "volume",
    categoryXField: "year",
    stroke: am5.color(0xffffff), 
    tooltip: am5.Tooltip.new(root, {
      labelText: "{categoryX} :{valueY} KHL[/]",
      getFillFromSprite: false,
      background: am5.Rectangle.new(root, {
        fill: am5.color(0x87CEEB), 
        fillOpacity: 0.9,
        stroke: am5.color(0x000000),
       
       
        shadowBlur: 6,
        shadowColor: am5.color(0x000000),
      }),
    }),
  })
);

// PCC Series
let pccSeries = chart.series.push(
  am5xy.SmoothedXLineSeries.new(root, {
    name: "Total PCC",
    xAxis: xAxis,
    yAxis: yAxisRight,
    valueYField: "pcc",
    categoryXField: "year",
    stroke: am5.color(0xffd700), 
    tooltip: am5.Tooltip.new(root, {
      labelText: "{categoryX}:{valueY} L/pers[/]",
      getFillFromSprite: false,
      background: am5.Rectangle.new(root, {
        fill: am5.color(0xffd700), 
        fillOpacity: 0.9,
        stroke: am5.color(0x000000), 
      
        shadowBlur: 6,
        shadowColor: am5.color(0x000000),
      }),
    }),
  })
);

// Stroke Settings
volumeSeries.strokes.template.setAll({
  strokeWidth: 4,
  cursorOverStyle: "pointer",
});

pccSeries.strokes.template.setAll({
  strokeDasharray: [5, 5], 
  strokeWidth: 2,
  cursorOverStyle: "pointer",
});

// Hover Effect
volumeSeries.strokes.template.states.create("hover", {
  strokeWidth: 6,
  stroke: am5.color(0xbbbbbb),
});

pccSeries.strokes.template.states.create("hover", {
  strokeWidth: 3,
  stroke: am5.color(0xffe600),
});

// Set Data
volumeSeries.data.setAll(dualaxischartData);
pccSeries.data.setAll(dualaxischartData);

      // Remove logo
      if (root._logo) {
        root._logo.dispose();
      }

    return () => {
      root.dispose();
    };
  }, [dualaxischartData]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }}></div>;
};

export default DualAxisChart;
