import React, { useEffect } from "react";
import DynamicHead from "../../components/DynamicHead";

const RedirectToYPF: React.FC = () => {
  useEffect(() => {
    window.location.href = process.env.REACT_APP_DEV_REDIRECT_URI_YP || "";
  }, []);

  return (
    <>
      <DynamicHead title="10YP Login" favicon="/favicon.png" />
    </>
  );
};

export default RedirectToYPF;
