
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect, useRef } from "react";

interface ChartDataItem {
  year: string;
  value3: number;
}

interface LineChartWithBars {
  linechartData: ChartDataItem[];
}

const calculateCAGR = (start: number, end: number, years: number) => {
  return (((end / start) ** (1 / years) - 1) * 100).toFixed(1);
};

const LineChartWithBars2: React.FC<LineChartWithBars> = ({ linechartData }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!chartRef.current || !linechartData.length) return;

    let root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

      let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
              categoryField: "year",
              renderer: am5xy.AxisRendererX.new(root, {})
            })
          );
      
          
          xAxis.get("renderer").labels.template.setAll({
            fill: am5.color(0xffffff), 
            fontSize: 14,
            text: "{category}", 
          });
      
          xAxis.get("renderer").labels.template.setAll({
            rotation: -90,
            centerY: am5.p0,
            centerX: am5.p0,
            paddingRight: 15
          });
          xAxis.get("renderer").set("minGridDistance", 30);
      
          xAxis.get("renderer").grid.template.setAll({
            location:1
          })
          
          linechartData.forEach((data) => {
            data.year = String(data.year);
          });
      
          xAxis.data.setAll(linechartData);

    
          let minValue = Math.min(...linechartData.map((data) => data.value3));
          console.log("minvalue_test" ,minValue*0.50);

   
    
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: minValue*0.5, 
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );
    
    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xffffff),
      fontSize: 14,
    });

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Persons",
        y: am5.p50,
        centerY: am5.p50,
        paddingRight: 10,
        fill: am5.color(0xffffff),
      })
    );

    yAxis.set(
      "numberFormatter",
      am5.NumberFormatter.new(root, { numberFormat: "#a" })
    );

   
    let series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Value",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value3",
        categoryXField: "year",
        stroke: am5.color(0xDCC386), 
      })
    );
    series1.strokes.template.setAll({
      strokeWidth: 4, 
    });

    series1.data.setAll(linechartData);

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineY.setAll({ visible: false });
    cursor.lineX.setAll({ visible: false });

    const createTooltip = () => {
      let tooltip = am5.Tooltip.new(root, {
        labelText: "[bold]{categoryX}[/]: {valueY}",
        pointerOrientation: "horizontal",
        getFillFromSprite: false,
      });

     
      tooltip.get("background")?.setAll({
        fill: am5.color(0xd3d3d3), 
        fillOpacity: 0.7, 
        stroke: am5.color(0x000000), 
        strokeWidth: 1,
        shadowOpacity: 0.3, 
        shadowBlur: 5,
        shadowOffsetX: 2,
        shadowOffsetY: 2,
      });

      // Set tooltip text color to black for contrast
      tooltip.label.setAll({
        fill: am5.color(0x000000), 
        fontSize: 12,
      });

      return tooltip;
    };

    const createLineSeries = (field: string, name: string, color: number) => {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "year",
          stroke: am5.color(color),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 4, // Makes the line thicker
      });

     
      series.setAll({
        snapTooltip: true,
      });

      series.set("tooltip", createTooltip());
      series.data.setAll(linechartData);
      return series;
    };

  
    series1.set("tooltip", createTooltip());

   

    if (root._logo) {
      root._logo.dispose();
    }

    return () => {
      root.dispose();
    };
  }, [linechartData]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default LineChartWithBars2;

