import { useState, useEffect } from "react";
import Loader from "../../components/loader/loader";
import { Link, useNavigate } from "react-router-dom";
import InfoIcon from "../../assets/images/info_icon.svg";
import Footer from "../../components/footer/footer";
import AreaChartYp from "../../graphs/yp_graphs/AreaChartYp";
import AreaChartYp2 from "../../graphs/yp_graphs/AreaChartYp2";
import BarsYp from "../../graphs/yp_graphs/Bars_Yp";
import LineChartWithBars from "../../graphs/yp_graphs/LineChartWithBars";
import LineChartWithBars2 from "../../graphs/yp_graphs/LineChartWithBar2";
import ChartComponent from "../../graphs/yp_graphs/bevolution";
import DualAxisChart from "../../graphs/yp_graphs/dualaxis";
import StackedBarChart from "../../graphs/yp_graphs/StackedBarChart";
import StackedBarChart2 from "../../graphs/yp_graphs/StackedBarChart2";
import StackedBarChart3 from "../../graphs/yp_graphs/StackedBarChart3";
import StackedBarChart4 from "../../graphs/yp_graphs/StackedBarChart4";
import DonutChart from "../../graphs/yp_graphs/donut_Yp";
import VolEvolution from "../../graphs/yp_graphs/volevolution";
import ComponentLoader from "../../components/loader/ComponentLoader";
import DynamicHead from "../../components/DynamicHead";
import downloadIcon from "../../graphs/yp_graphs/downloads.png";
import excelIcon from "../../graphs/yp_graphs/excell.png";
import { toast } from "react-toastify";

export default function HomeYP() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("MarketContent");
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [BarchartData, setBarChartData] = useState([]);
  const [linechartData, setLineChartData] = useState([]);
  const [linechartLegend, setlinechartLegend] = useState<number[]>([]);
  const [BeerRevochartData, setBeerRevochartData] = useState([]);
  const [cagr, setCagr] = useState(0);
  const [forcagr, setforcagr] = useState(0);
  const [vtcagr, setvtCagr] = useState(0);
  const [vtcagr2, setvtCagr2] = useState(0);
  const [popfirstcagr, setpopfirstcagr] = useState(0);
  const [popseccagr, setpopseccagr] = useState(0);
  const [popthirdcagr, setpopthirdcagr] = useState(0);
  // const [zonesData,setzonesData] = useState({});
  const [initialSetupComplete, setInitialSetupComplete] = useState(false);

  const [Stackedchart, setStackedchart] = useState<any[]>([]);
  const [Stackedchart2, setStackedchart2] = useState<any[]>([]);
  const [Stackedchart3, setStackedchart3] = useState<any[]>([]);
  const [Stackedchart4, setStackedchart4] = useState<any[]>([]);
  const [segevol, setsegevol] = useState<any[]>([]);
  // const [segevol1, setsegevol1] = useState<any[]>([]);
  // const [Stackedchart3, setStackedchart3] = useState(0);
  // const [Stackedchart4, setStackedchart4] = useState(0);
  const [dualaxischartData, setdualaxischartData] = useState([]);
  type ChartData = {
    year: string;
    totalAlcohol: number;
    data: { category: string; value: number }[];
  };

  const [circlechartData, setCircleChartData] = useState<ChartData[]>([]);

  const [volevolchart, setvolevolchart] = useState<any[]>([]);
  interface CagrData {
    Beer?: {
      // "2009_2019": number;
      "2015_2019": number;
      "2015_2025": number;
      "2025_2035": number;
    };
    "Beyond Beer"?: {
      // "2009_2019": number;
      "2015_2019": number;
      "2015_2025": number;
      "2025_2035": number;
    };
    Wine?: {
      // "2009_2019": number;
      "2015_2019": number;
      "2015_2025": number;
      "2025_2035": number;
    };
    Spirits?: {
      // "2009_2019": number;
      "2015_2019": number;
      "2015_2025": number;
      "2025_2035": number;
    };
    "Total Alcohol"?: {
      // "2009_2019": number;
      "2015_2019": number;
      "2015_2025": number;
      "2025_2035": number;
    };
    BeerBeyondBeer?: {
      // "2009_2019": number;
      "2015_2019": number;
      "2015_2025": number;
      "2025_2035": number;
    };
  }
  const [volcagr, setvolcagr] = useState<CagrData>({});
  // const [volcagr2, setvolcagr2] = useState(0);
  const [kpiData, setkpiData] = useState({
    totalVolume: 0,
    growthRate: 0,
    beerPCC: 0,
    beerPCCGrowthRate: 0,
    disposableIncome: 0,
    disposableIncomeGrowth: 0,
  });
  interface APIResponse {
    country: string;
    values: Record<string, Record<string, number>>;
  }

  // const [value, setvalue] = useState(0);
  const [value, setValue] = useState<APIResponse | null>(null);
  // const [cagrdata, setcagrdata] = useState<APIResponse | null>(null);
  const [cagrValues, setCagrValues] = useState({
    core_2015_2024: 0,
    core_2025_2034: null,
    mainstream_2015_2024: null,
    mainstream_2025_2034: null,
    premium_2015_2024: null,
    premium_2025_2034: null,
    core_2015_2024_2: null,
    core_2025_2034_2: null,
    mainstream_2015_2024_2: null,
    mainstream_2025_2034_2: null,
    premium_2015_2024_2: null,
    premium_2025_2034_2: null,
  });

  const [cagrValues_2, setCagrValues_2] = useState({
    core_2015_2024_sw: 0,
    core_2025_2034_sw: null,
    mainstream_2015_2024_sw: null,
    mainstream_2025_2034_sw: null,
    premium_2015_2024_sw: null,
    premium_2025_2034_sw: null,
    core_2015_2024_2_sw: null,
    core_2025_2034_2_sw: null,
    mainstream_2015_2024_2_sw: null,
    mainstream_2025_2034_2_sw: null,
    premium_2015_2024_2_sw: null,
    premium_2025_2034_2_sw: null,
  });

  const [zonesData, setzonesData] = useState<Record<string, string[]>>({});

  const [selectedZone, setSelectedZone] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [showPCCChart, setShowPCCtChart] = useState(true);
  const [showPopulationChart, setShowPopulationChart] = useState(true);
  const [showSegmentChart, setShowSegmentChart] = useState(true);

  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const files = [
    {
      displayName: <><img src={excelIcon} alt="Excel Icon" className="w-2 h-2 mr-3" style={{ width: "30px", height: "30px" }} />
        10YP 25-Volume</>,
      name: "10YP 2025.xlsx"
    },
    {
      displayName: <><img src={excelIcon} alt="Excel Icon" className="w-2 h-2 mr-3" style={{ width: "30px", height: "30px" }} />10YP 25-Macros</>,
      name: "Macros_25"

      
    } ,
    {
      displayName: <><img src={excelIcon} alt="Excel Icon" className="w-2 h-2 mr-3" style={{ width: "30px", height: "30px" }} />10YP 24-Volume</>,
      name: "10YP 2024.xlsx"
    }

  
    , {
      displayName: <><img src={excelIcon} alt="Excel Icon" className="w-2 h-2 mr-3" style={{ width: "30px", height: "30px" }} />10YP 24-Macros</>,
      name: "Macros_24"

      
    } 
  ];
  async function download_ypExcel(file_name: string, country_name: any) {
    try {
      const access_token = localStorage.getItem("accesstoken") || "";
      const role_token = localStorage.getItem("roleToken") || "";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/download_excel`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
            "X-Role-Key": role_token,
          },
          body: JSON.stringify({ country: country_name, file_name: file_name }),
        }
      );

      if (response.status === 200) {
        const blob = await response.blob(); // Get binary data as Blob
        const url = URL.createObjectURL(blob);

        // Create a download link dynamically
        const link = document.createElement("a");
        link.href = url;
        link.download = `${country_name}-${file_name}`; // Save with correct file extension
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (response.status === 401) {
        logout(); // Handle unauthorized access
      } else {
        toast.error(`Error While Downloading the ${country_name}-${file_name} Excel`);
      }
    } catch (e) {
      console.error("Download Error:", e);
      toast.error(`Error While Downloading the ${country_name}-${file_name} Excel`);
    }
  }

  useEffect(() => {
    const zones = localStorage.getItem("userObj");
    try {
      const parsedZones: Record<string, string[]> = zones
        ? JSON.parse(zones).zones
        : {};
      setzonesData(parsedZones);
      setSelectedZone(Object.keys(parsedZones)[0]);
      setSelectedCountry(parsedZones[Object.keys(parsedZones)[0]][0]);
      setInitialSetupComplete(true);
    } catch (error) {
      console.error("Error parsing zones from localStorage:", error);
      setzonesData({}); // Fallback in case of error
      setInitialSetupComplete(true); // Fallback in case of error
    }
  }, []);

  function logout() {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("roles");
    localStorage.removeItem("zones");
    localStorage.removeItem("homeRoute");
    localStorage.removeItem("roleToken");
    localStorage.removeItem("userObj");
    navigate("/10yp");
  }

  const fetchData = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_volume_trend`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          const tabledata = data["volume_trend"];
          const cagrdata = data["cagr"];

          console.log("data", data);
          setChartData(tabledata);
          setvtCagr(cagrdata);
          // Assuming the API returns data in the format [{ year: "2015", volume: 27 }, ...]
          console.log("chartData", chartData);
        } else if (response.status === 401) {
          logout();
        }
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const fetchData2 = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_pcc_trend`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log("data", data);
          const tabledata2 = data["pcc"];
          const cagrdata = data["cagr"];

          setChartData2(tabledata2);
          setvtCagr2(cagrdata);
          // Assuming the API returns data in the format [{ year: "2015", volume: 27 }, ...]
          console.log("chartData2", chartData);
        } else if (response.status === 401) {
          logout();
        } else {
          console.error("Failed to fetch data: ", response.status);
        }
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const barfetchdata = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_income_trend`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        const result = await response.json();

        const { data, cagr } = result;
        setBarChartData(data); // Store the data array for the bar chart
        setCagr(cagr);
        // Assuming the API returns data in the format [{ year: "2015", volume: 27 }, ...]
        console.log("barchartData", chartData);
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const linefetchdata = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/population-trend`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log("Fetched Data pop trend:", data);

          // Transform data into required format
          const transformedData = data.population_15_24_55_plus.years.map(
            (year: any, index: any) => ({
              // year: year.toString(), // Ensure year is a string
              // value1: data.population_15_24[index],
              // value2: data.population_55_plus[index],
              year: data.population_15_24_55_plus.years[index],
              value1: data.population_15_24_55_plus.population_15_24[index],
              value2: data.population_15_24_55_plus.population_55_plus[index],
              value3: data.population_18_plus.population[index],
            })
          );
          const firstcagr = data.population_15_24_55_plus.cagr_15_24;
          const seccagr = data.population_15_24_55_plus.cagr_55_plus;
          const thirdcagr = data.population_18_plus.cagr;
          setpopfirstcagr(firstcagr);
          setpopseccagr(seccagr);
          setpopthirdcagr(thirdcagr);
          console.log("transformedData", transformedData);
          setlinechartLegend([
            data.population_15_24_55_plus.cagr_15_24,
            data.population_15_24_55_plus.cagr_55_plus,
          ]);
          setLineChartData(transformedData); // ✅ Store structured data in state
        } else if (response.status === 401) {
          logout();
        }
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const segmentvolfetch1 = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_segment_volume`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        const result = await response.json();
        console.log("Fetched Data pop trend:", result);
        const temp_dataset1 = result["Dataset 1"];
        const temp_dataset2 = result["Dataset 2"];
        const dataset1 = temp_dataset1.map((item: any) => ({
          ...item,
          "Core+": parseFloat(item["Core+"].toFixed(2)),
          Mainstream: parseFloat(item["Mainstream"].toFixed(2)),
          "Premium / SP": parseFloat(item["Premium / SP"].toFixed(2)),
        }));
        const dataset2 = temp_dataset2.map((item: any) => ({
          ...item,
          "Core+": parseFloat(item["Core+"].toFixed(2)),
          Mainstream: parseFloat(item["Mainstream"].toFixed(2)),
          "Premium / SP": parseFloat(item["Premium / SP"].toFixed(2)),
        }));
        // Extract Dataset 1 and Dataset 2 from the result
        // const dataset1 = result["Dataset 1"];
        // const dataset2 = result["Dataset 2"];
        // const cagrdataset = result["CAGR"];

        // const cagrdataset = result["CAGR"]["CAGR_Dataset_1"];

        const cagrdataset = result["CAGR"];

        setCagrValues({
          core_2015_2024:
            cagrdataset?.["CAGR_Dataset_1"]?.["Core+"]?.["2015-2024"] ?? "N/A",
          core_2025_2034:
            cagrdataset?.["CAGR_Dataset_1"]?.["Core+"]?.["2025-2034"] ?? "N/A",
          mainstream_2015_2024:
            cagrdataset?.["CAGR_Dataset_1"]?.["Mainstream"]?.["2015-2024"] ??
            "N/A",
          mainstream_2025_2034:
            cagrdataset?.["CAGR_Dataset_1"]?.["Mainstream"]?.["2025-2034"] ??
            "N/A",
          premium_2015_2024:
            cagrdataset?.["CAGR_Dataset_1"]?.["Premium / SP"]?.["2015-2024"] ??
            "N/A",
          premium_2025_2034:
            cagrdataset?.["CAGR_Dataset_1"]?.["Premium / SP"]?.["2025-2034"] ??
            "N/A",
          core_2015_2024_2:
            cagrdataset?.["CAGR_Dataset_2"]?.["Core+"]?.["2015-2024"] ?? "N/A",
          core_2025_2034_2:
            cagrdataset?.["CAGR_Dataset_2"]?.["Core+"]?.["2025-2034"] ?? "N/A",
          mainstream_2015_2024_2:
            cagrdataset?.["CAGR_Dataset_2"]?.["Mainstream"]?.["2015-2024"] ??
            "N/A",
          mainstream_2025_2034_2:
            cagrdataset?.["CAGR_Dataset_2"]?.["Mainstream"]?.["2025-2034"] ??
            "N/A",
          premium_2015_2024_2:
            cagrdataset?.["CAGR_Dataset_2"]?.["Premium / SP"]?.["2015-2024"] ??
            "N/A",
          premium_2025_2034_2:
            cagrdataset?.["CAGR_Dataset_2"]?.["Premium / SP"]?.["2025-2034"] ??
            "N/A",
        });
        // Update the state variables
        setStackedchart3(dataset1);
        setStackedchart4(dataset2);
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const segmentvolfetch2 = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_segment_weight`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );

        const result = await response.json();
        console.log("Fetched Data pop trend:", result);
        const temp_dataset1 = result["Dataset 1"];
        const temp_dataset2 = result["Dataset 2"];
        const dataset1 = temp_dataset1.map((item: any) => ({
          ...item,
          "Core+": parseFloat(item["Core+"].toFixed(2)),
          Mainstream: parseFloat(item["Mainstream"].toFixed(2)),
          "Premium / SP": parseFloat(item["Premium / SP"].toFixed(2)),
        }));
        const dataset2 = temp_dataset2.map((item: any) => ({
          ...item,
          "Core+": parseFloat(item["Core+"].toFixed(2)),
          Mainstream: parseFloat(item["Mainstream"].toFixed(2)),
          "Premium / SP": parseFloat(item["Premium / SP"].toFixed(2)),
        }));

        // const cagrdataset = result["CAGR"];

        // const cagrdataset = result["CAGR"]["CAGR_Dataset_1"];

        const cagrdataset = result["CAGR"];

        setCagrValues_2({
          core_2015_2024_sw:
            cagrdataset?.["CAGR_Dataset_1"]?.["Core+"]?.["2015-2024"] ?? "N/A",
          core_2025_2034_sw:
            cagrdataset?.["CAGR_Dataset_1"]?.["Core+"]?.["2025-2034"] ?? "N/A",
          mainstream_2015_2024_sw:
            cagrdataset?.["CAGR_Dataset_1"]?.["Mainstream"]?.["2015-2024"] ??
            "N/A",
          mainstream_2025_2034_sw:
            cagrdataset?.["CAGR_Dataset_1"]?.["Mainstream"]?.["2025-2034"] ??
            "N/A",
          premium_2015_2024_sw:
            cagrdataset?.["CAGR_Dataset_1"]?.["Premium / SP"]?.["2015-2024"] ??
            "N/A",
          premium_2025_2034_sw:
            cagrdataset?.["CAGR_Dataset_1"]?.["Premium / SP"]?.["2025-2034"] ??
            "N/A",
          core_2015_2024_2_sw:
            cagrdataset?.["CAGR_Dataset_2"]?.["Core+"]?.["2015-2024"] ?? "N/A",
          core_2025_2034_2_sw:
            cagrdataset?.["CAGR_Dataset_2"]?.["Core+"]?.["2025-2034"] ?? "N/A",
          mainstream_2015_2024_2_sw:
            cagrdataset?.["CAGR_Dataset_2"]?.["Mainstream"]?.["2015-2024"] ??
            "N/A",
          mainstream_2025_2034_2_sw:
            cagrdataset?.["CAGR_Dataset_2"]?.["Mainstream"]?.["2025-2034"] ??
            "N/A",
          premium_2015_2024_2_sw:
            cagrdataset?.["CAGR_Dataset_2"]?.["Premium / SP"]?.["2015-2024"] ??
            "N/A",
          premium_2025_2034_2_sw:
            cagrdataset?.["CAGR_Dataset_2"]?.["Premium / SP"]?.["2025-2034"] ??
            "N/A",
        });
        // Update the state variables
        setStackedchart(dataset1);
        setStackedchart2(dataset2);
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const beerRevofetchdata = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/forecast_graph`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );

        const result = await response.json();
        console.log("API Response:", result);

        if (result?.values) {
          const { forecast_graph, cagr_2009_2019 } = result.values;

          setBeerRevochartData(forecast_graph || []); // Set forecast_graph data
          setforcagr(cagr_2009_2019 || 0); // Set CAGR value
        }
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const dualAxisfetchData = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_combined_graph`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log("data", data);
          const modifiedData = data.map((item: any) => ({
            ...item,
            volume: Math.round(item.volume), // Ensures volume is an integer
            pcc: parseFloat((item.pcc * 100000).toFixed(1)), // Ensures pcc remains a number
            pcc_hover: parseFloat((item.pcc * 100000).toFixed(1)), // Ensures pcc remains a number
          }));

          setdualaxischartData(modifiedData); // Assuming the API returns data in the format [{ year: "2015", volume: 27 }, ...]
        } else if (response.status === 401) {
          logout();
        }
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const kpifetchData = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      }
      const access_token = localStorage.getItem("accesstoken") || "";
      const role_token = localStorage.getItem("roleToken") || "";
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/kpi`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
            "X-Role-Key": role_token,
          },
          body: JSON.stringify({ country: selectedCountry }),
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log("data", data);

        // Extract the values from the API response
        const totalVolume = data.kpi["Total Volume (mHL)"];
        const growthRate = data.kpi["Growth Rate (%)"];
        const beerPCC = data.kpi["Beer PCC (liters)"];
        const beerPCCGrowthRate = data.kpi["Beer PCC Growth Rate (%)"];
        const disposableIncome = data.kpi["Disposable Income"];
        const disposableIncomeGrowth =
          data.kpi["DisposableInc_coP_percap growth"];

        // Set the extracted values to state
        setkpiData({
          totalVolume,
          growthRate,
          beerPCC,
          beerPCCGrowthRate,
          disposableIncome,
          disposableIncomeGrowth,
        });
      } else if (response.status === 401) {
        logout();
      }
      return;
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  const fetchData1 = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_donut_chart`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();

          console.log("data", data);

          setCircleChartData(data);

          // Assuming the API returns data in the format [{ year: "2015", volume: 27 }, ...]
          console.log("donutchartData", data);
        } else if (response.status === 401) {
          logout();
        }
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };
  const fetchData4 = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/generate_alcohol_trend`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          const tabledata = data["trend_data"];
          const cagr = data["cagr"];
          // const cagr2 = data["cagr_25_35"];

          setvolevolchart(tabledata);
          setvolcagr(cagr);
          // setvolcagr2(cagr2);

          console.log("data", data);
          // Assuming the API returns data in the format [{ year: "2015", volume: 27 }, ...]
          console.log("chartData", chartData);
        } else if (response.status === 401) {
          logout();
        }
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };
  const fetchData3 = async () => {
    try {
      if (
        selectedCountry === null ||
        selectedCountry === "" ||
        selectedCountry === undefined
      ) {
        return;
      } else {
        const access_token = localStorage.getItem("accesstoken") || "";
        const role_token = localStorage.getItem("roleToken") || "";
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/YP/values`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              "X-Role-Key": role_token,
            },
            body: JSON.stringify({ country: selectedCountry }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log("data", data);
          setValue(data);
        } else if (response.status === 401) {
          logout();
        }
      }
      return;
    } catch (error) {
      console.error("Error fetching data:", error);
      return;
    }
  };

  useEffect(() => {
    // debugger
    console.log(
      "loading",
      loading,
      document.getElementById("graph_div")?.classList
    );
    if (loading) {
      document.getElementById("graph_div")?.classList.add("blur");
    } else {
      document.getElementById("graph_div")?.classList.remove("blur");
    }
  }, [loading]);

  // api calls for section 1
  useEffect(() => {
    if (
      selectedCountry === null ||
      selectedCountry === "" ||
      selectedCountry === undefined
    ) {
      return;
    } else {
      setLoading(true); // Show loader before fetching data
      Promise.all([
        fetchData(),
        fetchData2(),
        barfetchdata(),
        linefetchdata(),
        segmentvolfetch1(),
        segmentvolfetch2(),
        beerRevofetchdata(),
        dualAxisfetchData(),
        kpifetchData(),
        fetchData1(),
        fetchData4(),
        fetchData3(),
      ])
        .then(() => {
          setLoading(false); // Hide loader after all API calls are resolved
        })
        .catch((error) => {
          debugger;
          console.error("Error fetching data:", error);
          setLoading(false); // Hide loader even if there's an error
        });
    }
  }, [selectedCountry, initialSetupComplete]);

  const calculateCAGR = (start: number, end: number, years: number) => {
    return (((end / start) ** (1 / years) - 1) * 100).toFixed(1);
  };

  return (
    <>
      <DynamicHead title="10YP Login" favicon="/logo-10.png" />
      <div className="header2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="backBtn">
                <Link to="/10yp">
                  <i className="fa-solid fa-arrow-left"></i> Back
                </Link>
              </div>
            </div>
            <div className="col-md-9">
              <div className="ZonesTabWrapper">
                <ul className="Zones">
                  {zonesData != null &&
                    Object.keys(zonesData).map((zone) => (
                      <li
                        key={zone}
                        className={selectedZone === zone ? "active" : ""}
                        onClick={() => setSelectedZone(zone)}
                      >
                        {zone}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bgBlack">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xxl-2">
              <div className="countriesTabbs">
                <ul className="countriesTabbsBtns">
                  {zonesData != null &&
                    selectedZone &&
                    zonesData[selectedZone]?.map((country) => (
                      <li
                        key={country}
                        className={selectedCountry === country ? "active" : ""}
                        onClick={() => setSelectedCountry(country)}
                      >
                        {country}
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="col-md-9 col-xxl-10">
              {loading ? <ComponentLoader /> : null}
              <div id="graph_div">
                <div className="InnerTabbs mb-3">
                  <ul className="InnerTabbsBtns">
                    <li
                      className={activeTab === "MarketContent" ? "active" : ""}
                      onClick={() => setActiveTab("MarketContent")}
                    >
                      Market Context
                    </li>
                    <li
                      className={activeTab === "PF" ? "active" : ""}
                      onClick={() => setActiveTab("PF")}
                    >
                      10YP 2025 PF vs LY
                    </li>
                    <li
                      className={
                        activeTab === "IndustryOutlook" ? "active" : ""
                      }
                      onClick={() => setActiveTab("IndustryOutlook")}
                    >
                      10YP 2025 - Industry Outlook
                    </li>
                  </ul>
                </div>
                <div className="InnerTabContent">
                  {activeTab === "MarketContent" && (
                    <div id="MarketContent">
                      <div className="row mb-4">
                        <div className="col-md-4">
                          <div className="greyCard kpCard">
                            <div className="row align-items-center">
                              <div className="col-12">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="25"
                                  viewBox="0 0 41 39"
                                  fill="none"
                                >
                                  <path
                                    d="M30.4655 11.7287C30.0959 11.6969 29.8272 11.3861 29.8608 11.0372L30.0825 8.71524L18.2029 17.5144C17.9274 17.7174 17.531 17.6857 17.2958 17.4446L14.1647 14.2916L5.78581 20.4898C5.66486 20.5786 5.51704 20.623 5.37594 20.623C5.17436 20.623 4.9795 20.5405 4.84512 20.3819C4.61666 20.1028 4.67042 19.7094 4.95934 19.4938L13.822 12.934C14.0908 12.731 14.4872 12.7627 14.7224 12.9975L17.8535 16.1568L29.1552 7.78901L26.8237 7.586C26.4541 7.55428 26.1786 7.24977 26.2122 6.90085C26.2458 6.55193 26.5683 6.29182 26.9446 6.32354L30.956 6.67246C31.1374 6.68515 31.3121 6.76128 31.4331 6.91354C31.5607 7.05945 31.5943 7.23708 31.5607 7.40837L31.1979 11.1577C31.1643 11.4812 30.8754 11.7287 30.526 11.7287H30.4655Z"
                                    fill="#F1D513"
                                  ></path>
                                  <path
                                    d="M27.5491 21.2571V32.0419C27.5491 32.3908 27.2467 32.6763 26.8772 32.6763H22.8456C22.4694 32.6763 22.1737 32.3908 22.1737 32.0419V24.4291C22.1737 24.2451 22.2611 24.0611 22.4156 23.9406L26.4471 20.7686C26.642 20.6163 26.9242 20.5783 27.1594 20.6861C27.3945 20.7876 27.5491 21.0096 27.5491 21.2571Z"
                                    fill="#F1D513"
                                  ></path>
                                  <path
                                    d="M19.4867 21.8921V32.0425C19.4867 32.3915 19.1843 32.6769 18.8148 32.6769H14.7832C14.407 32.6769 14.1113 32.3915 14.1113 32.0425V24.4297C14.1113 24.2203 14.2188 24.0237 14.407 23.9031L18.4385 21.3655C18.6468 21.2387 18.9088 21.226 19.1306 21.3338C19.3456 21.448 19.4867 21.6574 19.4867 21.8921Z"
                                    fill="#F1D513"
                                  ></path>
                                  <path
                                    d="M35.6115 11.741V32.0419C35.6115 32.3908 35.3091 32.6763 34.9395 32.6763H30.908C30.5317 32.6763 30.2361 32.3908 30.2361 32.0419V14.913C30.2361 14.729 30.3234 14.545 30.478 14.4245L34.5095 11.2525C34.7044 11.1002 34.9866 11.0622 35.2217 11.17C35.4569 11.2715 35.6115 11.4936 35.6115 11.741Z"
                                    fill="#F1D513"
                                  ></path>
                                  <path
                                    d="M11.4222 27.6006V32.0414C11.4222 32.3903 11.1199 32.6758 10.7503 32.6758H6.7188C6.34252 32.6758 6.04688 32.3903 6.04688 32.0414V30.1382C6.04688 29.9288 6.15438 29.7322 6.34252 29.6116L10.374 27.074C10.5823 26.9471 10.8444 26.9345 11.0661 27.0423C11.2811 27.1565 11.4222 27.3659 11.4222 27.6006Z"
                                    fill="#F1D513"
                                  ></path>
                                </svg>
                              </div>
                              <div className="col-5">
                                <p>
                                  Volume<span className="font-10">, 2024</span>{" "}
                                  <span
                                    style={{ position: "relative", display: "inline-block" }}
                                    onMouseEnter={() => setShowTooltip1(true)}
                                    onMouseLeave={() => setShowTooltip1(false)}
                                  >
                                    <img src={InfoIcon} className="infoIcon" />
                                    {showTooltip1 && (
                                      <span
                                        style={{
                                          backgroundColor: "black",
                                          color: "#fff",
                                          textAlign: "center",
                                          padding: "5px 10px",
                                          borderRadius: "4px",
                                          position: "absolute",
                                          bottom: "100%",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          whiteSpace: "nowrap",
                                          fontSize: "12px"
                                        }}
                                      >
                                        Beer AC/LE
                                      </span>
                                    )}
                                  </span>
                                </p>
                              </div>
                              <div className="col-7 text-end">
                                <h1>
                                  {kpiData.totalVolume}{" "}
                                  <div className="yellow">Mhl</div>
                                </h1>
                              </div>
                              <div className="col-12">
                                <hr />
                              </div>
                              <div className="col-12 text-end">
                                <span>
                                  <div className="yellow">
                                    {kpiData.growthRate > 0
                                      ? `+${kpiData.growthRate}`
                                      : kpiData.growthRate}
                                    %
                                  </div>{" "}
                                  vs LY
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="greyCard kpCard">
                            <div className="row align-items-center">
                              <div className="col-12">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="25"
                                  viewBox="0 0 26 25"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_181_4153)">
                                    <path
                                      d="M8.86542 4.65142H17.0809L18.8539 3.22941C19.4576 2.7465 19.6831 1.96255 19.4279 1.23099C19.1727 0.499438 18.5096 0.027832 17.7389 0.027832H8.20744C7.43673 0.027832 6.77363 0.500466 6.51844 1.23099C6.26324 1.96152 6.48871 2.7465 7.09134 3.22838L8.86542 4.65142Z"
                                      fill="#F1D513"
                                    />
                                    <path
                                      d="M17.2204 6.19238H8.7241C5.92514 8.94701 3.74829 13.7802 3.74829 17.7514C3.74829 21.2026 5.57053 24.6867 9.64137 24.6867H16.5593C20.0368 24.6867 22.1962 22.0287 22.1962 17.7514C22.1962 13.7802 20.0193 8.94701 17.2204 6.19238ZM12.5828 14.669H13.3617C14.5598 14.669 15.5344 15.6461 15.5344 16.8472C15.5344 17.9271 14.7576 18.8158 13.7409 18.9966V19.8053C13.7409 20.2306 13.3965 20.5759 12.9722 20.5759C12.5479 20.5759 12.2036 20.2306 12.2036 19.8053V19.0357H11.1787C10.7544 19.0357 10.41 18.6905 10.41 18.2651C10.41 17.8397 10.7544 17.4945 11.1787 17.4945H13.3617C13.7122 17.4945 13.9971 17.2089 13.9971 16.8575C13.9971 16.4958 13.7122 16.2102 13.3617 16.2102H12.5828C11.3847 16.2102 10.41 15.233 10.41 14.0319C10.41 12.9521 11.1869 12.0633 12.2036 11.8825V11.0728C12.2036 10.6475 12.5479 10.3022 12.9722 10.3022C13.3965 10.3022 13.7409 10.6475 13.7409 11.0728V11.8434H14.7658C15.1901 11.8434 15.5344 12.1887 15.5344 12.614C15.5344 13.0394 15.1901 13.3846 14.7658 13.3846H12.5828C12.2323 13.3846 11.9474 13.6703 11.9474 14.0217C11.9474 14.3833 12.2323 14.669 12.5828 14.669Z"
                                      fill="#F1D513"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_181_4153">
                                      <rect
                                        width="24.5972"
                                        height="24.6591"
                                        fill="white"
                                        transform="translate(0.673828 0.027832)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="col-6">
                                <p>
                                  Beer PCC
                                  <span className="font-10">, 2024</span>{" "}
                                  <span
                                    style={{ position: "relative", display: "inline-block" }}
                                    onMouseEnter={() => setShowTooltip2(true)}
                                    onMouseLeave={() => setShowTooltip2(false)}
                                  >
                                    <img src={InfoIcon} className="infoIcon" />
                                    {showTooltip2 && (
                                      <span
                                        style={{
                                          backgroundColor: "black",
                                          color: "#fff",
                                          textAlign: "center",
                                          padding: "5px 10px",
                                          borderRadius: "4px",
                                          position: "absolute",
                                          bottom: "100%",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          whiteSpace: "nowrap",
                                          fontSize: "12px"
                                        }}
                                      >
                                        PCC calculated on Total Population
                                      </span>
                                    )}
                                  </span>
                                </p>
                              </div>
                              <div className="col-6 text-end">
                                <h1>
                                  {kpiData.beerPCC}{" "}
                                  <div className="yellow"> L/pers</div>
                                </h1>
                              </div>
                              <div className="col-12">
                                <hr />
                              </div>
                              <div className="col-12 text-end">
                                <span>
                                  <div className="yellow">
                                    {kpiData.beerPCCGrowthRate > 0
                                      ? `+${kpiData.beerPCCGrowthRate}`
                                      : kpiData.beerPCCGrowthRate}
                                    %
                                  </div>{" "}
                                  vs LY
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="greyCard kpCard">
                            <div className="row align-items-center">
                              <div className="col-12">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="25"
                                  viewBox="0 0 26 25"
                                  fill="none"
                                >
                                  <path
                                    d="M6.51617 16.9806C6.72843 16.9806 6.9005 16.8081 6.9005 16.5953C6.9005 16.3825 6.72843 16.21 6.51617 16.21C6.30391 16.21 6.13184 16.3825 6.13184 16.5953C6.13184 16.8081 6.30391 16.9806 6.51617 16.9806Z"
                                    fill="#F1D513"
                                  />
                                  <path
                                    d="M3.44157 19.2931H8.05354C8.35933 19.2931 8.6526 19.1713 8.86883 18.9546C9.08506 18.7378 9.20653 18.4438 9.20653 18.1372V15.0548C9.20653 14.7483 9.08506 14.4543 8.86883 14.2375C8.6526 14.0207 8.35933 13.8989 8.05354 13.8989H3.44157C3.13577 13.8989 2.84251 14.0207 2.62628 14.2375C2.41005 14.4543 2.28857 14.7483 2.28857 15.0548V18.1372C2.28857 18.4438 2.41005 18.7378 2.62628 18.9546C2.84251 19.1713 3.13577 19.2931 3.44157 19.2931ZM6.51622 15.4401C6.74426 15.4401 6.96718 15.5079 7.15678 15.6349C7.34639 15.7619 7.49418 15.9425 7.58144 16.1537C7.66871 16.3649 7.69154 16.5973 7.64705 16.8215C7.60257 17.0457 7.49275 17.2517 7.33151 17.4134C7.17026 17.575 6.96481 17.6851 6.74115 17.7297C6.51749 17.7743 6.28567 17.7514 6.07498 17.6639C5.8643 17.5764 5.68423 17.4283 5.55754 17.2382C5.43084 17.0481 5.36322 16.8246 5.36322 16.596C5.36322 16.2895 5.4847 15.9955 5.70093 15.7787C5.91715 15.5619 6.21042 15.4401 6.51622 15.4401Z"
                                    fill="#F1D513"
                                  />
                                  <path
                                    d="M21.8892 10.0454H5.74734C5.23787 10.046 4.74945 10.2492 4.3892 10.6103C4.02895 10.9715 3.82629 11.4612 3.82568 11.9719V13.1278H8.05333C8.56279 13.1284 9.05122 13.3316 9.41147 13.6927C9.77172 14.0539 9.97437 14.5435 9.97498 15.0543V18.1367C9.97437 18.6474 9.77172 19.1371 9.41147 19.4983C9.05122 19.8594 8.56279 20.0626 8.05333 20.0632H3.82568V21.2191C3.82629 21.7298 4.02895 22.2195 4.3892 22.5806C4.74945 22.9418 5.23787 23.145 5.74734 23.1456H21.8892C22.3987 23.145 22.8871 22.9418 23.2474 22.5806C23.6076 22.2195 23.8103 21.7298 23.8109 21.2191V11.9719C23.8103 11.4612 23.6076 10.9715 23.2474 10.6103C22.8871 10.2492 22.3987 10.046 21.8892 10.0454ZM19.5833 21.6044H17.2773C17.1753 21.6044 17.0776 21.5638 17.0055 21.4915C16.9334 21.4193 16.8929 21.3213 16.8929 21.2191C16.8929 21.1169 16.9334 21.0189 17.0055 20.9466C17.0776 20.8744 17.1753 20.8338 17.2773 20.8338H19.5833C19.6852 20.8338 19.7829 20.8744 19.855 20.9466C19.9271 21.0189 19.9676 21.1169 19.9676 21.2191C19.9676 21.3213 19.9271 21.4193 19.855 21.4915C19.7829 21.5638 19.6852 21.6044 19.5833 21.6044ZM21.8892 21.6044H21.1206C21.0187 21.6044 20.9209 21.5638 20.8488 21.4915C20.7767 21.4193 20.7362 21.3213 20.7362 21.2191C20.7362 21.1169 20.7767 21.0189 20.8488 20.9466C20.9209 20.8744 21.0187 20.8338 21.1206 20.8338H21.8892C21.9912 20.8338 22.0889 20.8744 22.161 20.9466C22.2331 21.0189 22.2736 21.1169 22.2736 21.2191C22.2736 21.3213 22.2331 21.4193 22.161 21.4915C22.0889 21.5638 21.9912 21.6044 21.8892 21.6044Z"
                                    fill="#F1D513"
                                  />
                                  <path
                                    d="M6.55957 9.27519H10.185C11.112 8.24033 11.9625 7.13905 12.7296 5.98011C13.3137 5.09122 13.8473 4.17005 14.3281 3.22099L10.3806 2.34982C10.2796 2.32634 10.174 2.33385 10.0774 2.3714C10.0433 2.38598 10.0118 2.40617 9.98434 2.43112C9.94813 2.46505 9.92013 2.50683 9.90248 2.55326C9.756 3.13756 9.57935 3.71385 9.37326 4.27979C9.29293 4.50018 9.03735 5.18331 8.65187 5.9751C8.07758 7.14891 7.37591 8.25563 6.55957 9.27519Z"
                                    fill="#F1D513"
                                  />
                                  <path
                                    d="M21.2236 9.27493C22.6329 6.84755 23.6395 5.064 23.7602 4.77811C23.8117 4.67364 23.822 4.5535 23.789 4.44174C23.7685 4.38612 23.7362 4.33563 23.6944 4.29378C23.6525 4.25193 23.602 4.21972 23.5465 4.19939L16.3441 1.59168C16.2616 1.5631 16.1723 1.5613 16.0887 1.58655C16.0052 1.6118 15.9317 1.6628 15.8787 1.73232C15.1853 3.36276 14.3458 4.9268 13.3705 6.40522C12.708 7.40536 11.9847 8.36374 11.2048 9.27493H21.2236Z"
                                    fill="#F1D513"
                                  />
                                </svg>
                              </div>
                              <div className="col-6">
                                <p>
                                  Disposable Income
                                  <span className="font-10">, 2024</span>{" "}
                                  <span
                                    style={{ position: "relative", display: "inline-block" }}
                                    onMouseEnter={() => setShowTooltip3(true)}
                                    onMouseLeave={() => setShowTooltip3(false)}
                                  >
                                    <img src={InfoIcon} className="infoIcon" />
                                    {showTooltip3 && (
                                      <span
                                        style={{
                                          backgroundColor: "black",
                                          color: "#fff",
                                          textAlign: "center",
                                          padding: "5px 10px",
                                          borderRadius: "4px",
                                          position: "absolute",
                                          bottom: "100%",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          whiteSpace: "nowrap",
                                          fontSize: "12px"
                                        }}
                                      >
                                        USD, PPP Adjusted
                                      </span>
                                    )}
                                  </span>
                                </p>
                              </div>
                              <div className="col-6 text-end">
                                <h1>
                                  {kpiData.disposableIncome.toLocaleString()}{" "}
                                  <div className="yellow"> USD</div>
                                </h1>
                              </div>
                              <div className="col-12">
                                <hr />
                              </div>
                              <div className="col-7">
                                <span
                                  style={{ color: "#C2C2C2", fontSize: "12px" }}
                                >
                                  per 18+ Adult{"  "}
                                  <img
                                    src={InfoIcon}
                                    className="infoIcon"
                                    style={{ width: "11px" }}
                                  />
                                </span>
                              </div>
                              <div className="col-5 text-end">
                                <span>
                                  <div className="yellow">
                                    {kpiData.disposableIncomeGrowth > 0
                                      ? `+${kpiData.disposableIncomeGrowth}`
                                      : kpiData.disposableIncomeGrowth}
                                    %
                                  </div>{" "}
                                  vs LY
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h2 className="ChartTitle mb-3"  >Beer Volume</h2>
                          <div className="chartWrapper">
                            <div className="row">
                              {/* <div className="col-6">
                              <p>
                                CAGR ‘15 - ’24{" "}
                                <span className="positive">+1.5%</span>
                              </p>
                            </div> */}
                              <div className="col-6">
                                <p>
                                  CAGR ‘15 - ’24{" "}
                                  <span
                                    className={
                                      showPCCChart
                                        ? vtcagr2 >= 0
                                          ? "positive"
                                          : "negative"
                                        : vtcagr >= 0
                                          ? "positive"
                                          : "negative"
                                    }
                                  >
                                    {showPCCChart
                                      ? vtcagr2 > 0
                                        ? `+${vtcagr2}%`
                                        : `${vtcagr2}%`
                                      : vtcagr > 0
                                        ? `+${vtcagr}%`
                                        : `${vtcagr}%`}
                                  </span>
                                </p>
                              </div>
                              <div className="col-6">
                                <div
                                  className="topToggle"
                                  style={{
                                    justifyContent: "end",
                                  }}
                                >
                                  <p>Volume</p>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={showPCCChart}
                                      defaultChecked
                                      onChange={(e) =>
                                        setShowPCCtChart(e.target.checked)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <p>
                                    Total PCC{" "}
                                    <img src={InfoIcon} className="infoIcon" />
                                  </p>
                                </div>
                              </div>

                              <div className="col-12">
                                {showPCCChart ? (
                                  <AreaChartYp2 chartData2={chartData2} />
                                ) : (
                                  <AreaChartYp chartData={chartData} />
                                )}

                                <></>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* on toggle */}
                        {/* <div className="col-12">
                        <h2 className="ChartTitle">Total pcc</h2>
                        <div className="chartWrapper mb-3">
                          <div className="row">
                            <div className="col-6">
                              <p>
                                CAGR ‘15 - ’24{" "}
                                <span className="positive">+1.5%</span>
                              </p>
                            </div>
                            <div className="col-6">
                              <div
                                className="topToggle"
                                style={{
                                  justifyContent: "end",
                                }}
                              >
                                <p>Volume</p>
                                <label className="switch">
                                  <input type="checkbox" defaultChecked />
                                  <span className="slider round"></span>
                                </label>
                                <p>
                                  Total PCC{" "}
                                  <img src={InfoIcon} className="infoIcon" />
                                </p>
                              </div>
                            </div>

                            <div className="col-12">
                              <AreaChartYp chartData={chartData} />
                            </div>
                          </div>
                        </div>
                      </div> */}
                        <div className="col-12">
                          <h2 className="ChartTitle mb-3 mt-4">
                            Macroeconomics
                          </h2>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="chartWrapper h-100">
                                <div className="row">
                                  <div className="col-12">
                                    <p>Disposable Income per 18+ Adult</p>
                                  </div>
                                  <div className="col-12">
                                    <span className="cgr">CAGR ‘15 - ’24</span>
                                    <div className="Gbox">
                                      <p
                                        className={
                                          cagr > 0 ? "positive" : "negative"
                                        }
                                      >
                                        {cagr > 0 ? `+${cagr}` : cagr}%
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <BarsYp BarchartData={BarchartData} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="chartWrapper h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p>
                                      Demography Trend{" "}
                                      {/* <img src={InfoIcon} className="infoIcon" /> */}
                                    </p>
                                  </div>
                                  <div
                                    className="col-6 topToggle"
                                    style={{ justifyContent: "end" }}
                                  >
                                    <p>Relevant Population </p>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        defaultChecked
                                        checked={showPopulationChart}
                                        onChange={(e) => {
                                          setShowPopulationChart(
                                            e.target.checked
                                          );
                                          setlinechartLegend([]);
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <p>18+ Population</p>
                                  </div>
                                  {showPopulationChart ? (
                                    <>
                                      <div
                                        className="dtBottom"
                                        style={{
                                          justifyContent: "end",
                                          padding: "0px 10px",
                                          height: "30px",
                                        }}
                                      >
                                        <p>
                                          {/* <span className="lYellow"></span> */}
                                          Pop 18+ CAGR(2015-2024):{" "}
                                          <b className="yellow">
                                            {" "}
                                            {popthirdcagr}%
                                          </b>
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="col-6">
                                        <span
                                          className="cgr population"
                                          style={{ marginLeft: "-10px" }}
                                        >
                                          Pop 15-24 CAGR(2015-24):
                                          <b className="yellow">
                                            {" "}
                                            {popfirstcagr}%
                                          </b>
                                        </span>
                                      </div>
                                      <div className="col-6 text-right">
                                        <span
                                          className="cgr population"
                                          style={{ marginLeft: "-10px" }}
                                        >
                                          Pop 55+ CAGR(2015-2024):
                                          <b className="yellow">
                                            {" "}
                                            {popseccagr}%
                                          </b>
                                        </span>
                                      </div>
                                    </>
                                  )}

                                  <div className="col-12">
                                    {showPopulationChart ? (
                                      <>
                                        <LineChartWithBars2
                                          linechartData={linechartData}
                                        />
                                      </>
                                    ) : (
                                      <LineChartWithBars
                                        linechartData={linechartData}
                                      />
                                    )}
                                  </div>
                                  {showPopulationChart ? (
                                    <div className="col-12">
                                      <div
                                        className="dtBottom"
                                        style={{
                                          justifyContent: "end",
                                        }}
                                      >
                                        <p>
                                          <span className="lYellow"></span>18+
                                          Population
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-12">
                                      <div
                                        className="dtBottom"
                                        style={{
                                          justifyContent: "end",
                                        }}
                                      >
                                        <p>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: "15x",
                                              height: "2px",
                                              backgroundColor: "#77A433",
                                              marginRight: "8px",
                                              borderRadius: "3px", // Optional for rounded edges
                                            }}
                                          ></span>
                                          Population 15-24
                                        </p>

                                        <p>
                                          <span className="lRed"></span>
                                          Population 55 +
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === "PF" && (
                    <div id="PF">
                      <div className="row">
                        <div className="col-12" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                          <h2 className="ChartTitle mb-3" style={{ fontSize: "20px", fontWeight: "bold", color: "white", margin: "0" }}>
                            Total Beer Evolution
                          </h2>

                          <a
                            className="download-icon"
                            title="Download"
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", position: "relative" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsOpen((prev) => !prev);
                            }}
                          >
                            <img
                              src={downloadIcon}
                              alt="Download"
                              style={{ width: "32px", height: "32px", cursor: "pointer", transition: "opacity 0.3s ease" }}
                              onMouseOver={(e) => (e.currentTarget.style.opacity = "0.7")}
                              onMouseOut={(e) => (e.currentTarget.style.opacity = "1")}
                            />
                          </a>
                          {/* Dropdown Menu */}
                          {isOpen && (
                            <div
                              style={{
                                position: "absolute",
                                top: "210px",
                                right: "0",
                                backgroundColor: "#222",
                                borderRadius: "8px",
                                padding: "8px 0",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                                minWidth: "140px",
                                zIndex: 100
                              }}
                            >
                              {files.map((file) => (
                                <a
                                  key={file.name}
                                  download
                                  style={{
                                    display: "block",
                                    padding: "10px",
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    download_ypExcel(file.name, selectedCountry); // Call function
                                    setIsOpen(false); // Close dropdown
                                  }}
                                >
                                  {file.displayName}
                                </a>
                              ))}

                            </div>
                          )}

                        </div>

                        <div className="col-12">
                          <div className="chartWrapper mb3">
                            <div className="row">
                              <div className="col-6">
                                <span className="cgr">CAGR ‘15 - ’25</span>
                                <div className="Gbox">
                                  <p
                                    className={
                                      forcagr > 0 ? "positive" : "negative"
                                    }
                                  >
                                    {forcagr > 0
                                      ? `+${forcagr}%`
                                      : `-${forcagr}%`}
                                  </p>

                                  {/* forcagr */}
                                </div>
                                {/* <span
                                      className={
                                        value?.values?.TenY25?.["24-30"] !==
                                          undefined &&
                                          value?.values?.TenY25?.["24-30"] > 0
                                          ? "positive"
                                          : "negative"
                                      }
                                    >
                                      {value?.values?.TenY25?.["24-30"] !==
                                        undefined
                                        ? value.values.TenY25["24-30"] > 0
                                          ? `+${value.values.TenY25["24-30"]}%`
                                          : `${value.values.TenY24["24-30"]}%`
                                        : "N/A"}
                                    </span> */}
                              </div>
                              <div className="col-12">
                                <ChartComponent
                                  BeerRevochartData={BeerRevochartData}
                                />
                              </div>
                              <div className="col-12">
                                <div className="dtBottom">
                                  <div
                                    style={{
                                      position: "absolute", // Ensures it's positioned at the bottom-right
                                      bottom: "10px", // Adjust as needed
                                      right: "10px", // Adjust as needed
                                      display: "flex", // Ensures horizontal alignment
                                      alignItems: "center",
                                      gap: "15px", // Adds spacing between items
                                    }}
                                  >
                                    <p>
                                      <span className="lwhite"></span>Actual
                                    </p>
                                    <p>
                                      <span className="lYellow"></span>10YP'25
                                    </p>
                                    <p>
                                      <span className="lDotedYellow"></span>
                                      10YP’24
                                    </p>
                                    {/* <p>
                                    <span className="lDotedGrey"></span>Org10YP
                                  </p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="chartWrapper mb3 mt-3">
                            <div className="style1Tble">
                              <table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>
                                      CAGR{" "}
                                      <span className="yellow">‘22 - ’24</span>
                                    </th>
                                    <th>
                                      CAGR{" "}
                                      <span className="yellow">‘24 - ’30</span>
                                    </th>
                                    <th>
                                      CAGR{" "}
                                      <span className="yellow">‘25 - ’34</span>
                                    </th>
                                    <th>
                                      CAGR{" "}
                                      <span className="yellow">‘25 - ’35</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="hightlighRow">
                                    <td>10YP’25</td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.TenY25?.["22-24"] !==
                                            undefined &&
                                            value?.values?.TenY25?.["22-24"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.TenY25?.["22-24"] !==
                                          undefined
                                          ? value.values.TenY25["22-24"] > 0
                                            ? `+${value.values.TenY25["22-24"]}%`
                                            : `${value.values.TenY25["22-24"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.TenY25?.["24-30"] !==
                                            undefined &&
                                            value?.values?.TenY25?.["24-30"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.TenY25?.["24-30"] !==
                                          undefined
                                          ? value.values.TenY25["24-30"] > 0
                                            ? `+${value.values.TenY25["24-30"]}%`
                                            : `${value.values.TenY24["24-30"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.TenY25?.["25-34"] !==
                                            undefined &&
                                            value?.values?.TenY25?.["25-34"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.TenY25?.["25-34"] !==
                                          undefined
                                          ? value.values.TenY25["25-34"] > 0
                                            ? `+${value.values.TenY25["25-34"]}%`
                                            : `${value.values.TenY25["25-34"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="greyHighlight">
                                        <span
                                          className={
                                            value?.values?.TenY25?.["25-35"] !==
                                              undefined &&
                                              value?.values?.TenY25?.["25-35"] > 0
                                              ? "positive"
                                              : "negative"
                                          }
                                        >
                                          {value?.values?.TenY25?.["25-35"] !==
                                            undefined
                                            ? value.values.TenY25["25-35"] > 0
                                              ? `+${value.values.TenY25["25-35"]}%`
                                              : `${value.values.TenY25["25-35"]}%`
                                            : "N/A"}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>10YP’24</td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.TenY24?.["22-24"] !==
                                            undefined &&
                                            value?.values?.TenY24?.["22-24"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.TenY24?.["22-24"] !==
                                          undefined
                                          ? value.values.TenY24["22-24"] > 0
                                            ? `+${value.values.TenY24["22-24"]}%`
                                            : `${value.values.TenY24["22-24"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.TenY24?.["24-30"] !==
                                            undefined &&
                                            value?.values?.TenY24?.["24-30"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.TenY24?.["24-30"] !==
                                          undefined
                                          ? value.values.TenY24["24-30"] > 0
                                            ? `+${value.values.TenY24["24-30"]}%`
                                            : `${value.values.TenY24["24-30"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.TenY24?.["25-34"] !==
                                            undefined &&
                                            value?.values?.TenY24?.["25-34"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.TenY24?.["25-34"] !==
                                          undefined
                                          ? value.values.TenY24["25-34"] > 0
                                            ? `+${value.values.TenY24["25-34"]}%`
                                            : `${value.values.TenY24["25-34"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>Org 10YP</td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.OG10YP?.["22-24"] !==
                                            undefined &&
                                            value?.values?.OG10YP?.["22-24"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.OG10YP?.["22-24"] !==
                                          undefined
                                          ? value.values.OG10YP["22-24"] > 0
                                            ? `+${value.values.OG10YP["22-24"]}%`
                                            : `${value.values.OG10YP["22-24"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          value?.values?.OG10YP?.["24-30"] !==
                                            undefined &&
                                            value?.values?.OG10YP?.["24-30"] > 0
                                            ? "positive"
                                            : "negative"
                                        }
                                      >
                                        {value?.values?.OG10YP?.["24-30"] !==
                                          undefined
                                          ? value.values.OG10YP["24-30"] > 0
                                            ? `+${value.values.OG10YP["24-30"]}%`
                                            : `${value.values.OG10YP["24-30"]}%`
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="chartWrapper mb3 mt-3">
                            <div className="style1Tble">
                              <table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Momentum</th>
                                    <th>Income</th>
                                    <th>Demography</th>
                                    <th>
                                      <span className="yellow">Volume</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="hightlighRow vBottom">
                                    <td>10YP’25</td>
                                    <td>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>2023 AC</p>
                                          <span
                                            className={
                                              value?.values?.momentum_10y25?.[
                                                "2023 AC"
                                              ] !== undefined &&
                                                value?.values?.momentum_10y25?.[
                                                "2023 AC"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.momentum_10y25?.[
                                              "2023 AC"
                                            ] !== undefined
                                              ? value.values.momentum_10y25[
                                                "2023 AC"
                                              ] > 0
                                                ? `+${value.values.momentum_10y25["2023 AC"]}%`
                                                : `${value.values.momentum_10y25["2023 AC"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                        <div className="colls">
                                          <p>2024 AC/LE</p>
                                          <span
                                            className={
                                              value?.values?.momentum_10y25?.[
                                                "2024 AC/LE"
                                              ] !== undefined &&
                                                value?.values?.momentum_10y25?.[
                                                "2024 AC/LE"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.momentum_10y25?.[
                                              "2024 AC/LE"
                                            ] !== undefined
                                              ? value.values.momentum_10y25[
                                                "2024 AC/LE"
                                              ] > 0
                                                ? `+${value.values.momentum_10y25["2024 AC/LE"]}%`
                                                : `${value.values.momentum_10y25["2024 AC/LE"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          <span
                                            className={
                                              value?.values?.income_10y25?.[
                                                "25-34 CAGR"
                                              ] !== undefined &&
                                                value?.values?.income_10y25?.[
                                                "25-34 CAGR"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.income_10y25?.[
                                              "25-34 CAGR"
                                            ] !== undefined
                                              ? value.values.income_10y25[
                                                "25-34 CAGR"
                                              ] > 0
                                                ? `+${value.values.income_10y25["25-34 CAGR"]}%`
                                                : `${value.values.income_10y25["25-34 CAGR"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex col-top">
                                        <p className="yellow">
                                          Pop 15-24 Vs 55+
                                        </p>
                                        <p className="yellow">LDA (18+)</p>
                                      </div>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          {/* <span className="negative">-2.4%</span> */}
                                          <span
                                            className={
                                              value?.values
                                                ?.final_aging_population_index?.[
                                                "new_value"
                                              ] !== undefined &&
                                                value?.values
                                                  ?.final_aging_population_index?.[
                                                "new_value"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values
                                              ?.final_aging_population_index?.[
                                              "new_value"
                                            ] !== undefined
                                              ? value.values
                                                .final_aging_population_index[
                                                "new_value"
                                              ] > 0
                                                ? `+${value.values.final_aging_population_index["new_value"]}%`
                                                : `${value.values.final_aging_population_index["new_value"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          <span
                                            className={
                                              value?.values?.lda_10y25?.[
                                                "25-34 CAGR"
                                              ] !== undefined &&
                                                value?.values?.lda_10y25?.[
                                                "25-34 CAGR"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.lda_10y25?.[
                                              "25-34 CAGR"
                                            ] !== undefined
                                              ? value.values.lda_10y25[
                                                "25-34 CAGR"
                                              ] > 0
                                                ? `+${value.values.lda_10y25["25-34 CAGR"]}%`
                                                : `${value.values.lda_10y25["25-34 CAGR"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          <span
                                            className={
                                              value?.values?.volume_10y25?.[
                                                "25-34 CAGR"
                                              ] !== undefined &&
                                                value?.values?.volume_10y25?.[
                                                "25-34 CAGR"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.volume_10y25?.[
                                              "25-34 CAGR"
                                            ] !== undefined
                                              ? value.values.volume_10y25[
                                                "25-34 CAGR"
                                              ] > 0
                                                ? `+${value.values.volume_10y25["25-34 CAGR"]}%`
                                                : `${value.values.volume_10y25["25-34 CAGR"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="vBottom">
                                    <td>10YP’24</td>
                                    <td>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>2023 LE</p>
                                          <span
                                            className={
                                              value?.values?.momentum_10y24?.[
                                                "2023 AC"
                                              ] !== undefined &&
                                                value?.values?.momentum_10y24?.[
                                                "2023 AC"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.momentum_10y24?.[
                                              "2023 AC"
                                            ] !== undefined
                                              ? value.values.momentum_10y24[
                                                "2023 AC"
                                              ] > 0
                                                ? `+${value.values.momentum_10y24["2023 AC"]}%`
                                                : `${value.values.momentum_10y24["2023 AC"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                        <div className="colls">
                                          <p>2024 LE</p>
                                          <span
                                            className={
                                              value?.values?.momentum_10y24?.[
                                                "2024 AC/LE"
                                              ] !== undefined &&
                                                value?.values?.momentum_10y24?.[
                                                "2024 AC/LE"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.momentum_10y24?.[
                                              "2024 AC/LE"
                                            ] !== undefined
                                              ? value.values.momentum_10y24[
                                                "2024 AC/LE"
                                              ] > 0
                                                ? `+${value.values.momentum_10y24["2024 AC/LE"]}%`
                                                : `${value.values.momentum_10y24["2024 AC/LE"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          <span
                                            className={
                                              value?.values?.income_10y24?.[
                                                "25-34 CAGR"
                                              ] !== undefined &&
                                                value?.values?.income_10y24?.[
                                                "25-34 CAGR"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.income_10y24?.[
                                              "25-34 CAGR"
                                            ] !== undefined
                                              ? value.values.income_10y24[
                                                "25-34 CAGR"
                                              ] > 0
                                                ? `+${value.values.income_10y24["25-34 CAGR"]}%`
                                                : `${value.values.income_10y24["25-34 CAGR"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex col-top">
                                        <p className="yellow">
                                          Pop 15-24 Vs 55+
                                        </p>
                                        <p className="yellow">LDA (18+)</p>
                                      </div>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          <span
                                            className={
                                              value?.values
                                                ?.final_aging_population_index2?.[
                                                "new_value2"
                                              ] !== undefined &&
                                                value?.values
                                                  ?.final_aging_population_index2?.[
                                                "new_value2"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values
                                              ?.final_aging_population_index2?.[
                                              "new_value2"
                                            ] !== undefined
                                              ? value.values
                                                .final_aging_population_index2[
                                                "new_value2"
                                              ] > 0
                                                ? `+${value.values.final_aging_population_index2["new_value2"]}%`
                                                : `${value.values.final_aging_population_index2["new_value2"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          <span
                                            className={
                                              value?.values?.lda_10y24?.[
                                                "25-34 CAGR"
                                              ] !== undefined &&
                                                value?.values?.lda_10y24?.[
                                                "25-34 CAGR"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.lda_10y24?.[
                                              "25-34 CAGR"
                                            ] !== undefined
                                              ? value.values.lda_10y24[
                                                "25-34 CAGR"
                                              ] > 0
                                                ? `+${value.values.lda_10y24["25-34 CAGR"]}%`
                                                : `${value.values.lda_10y24["25-34 CAGR"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="greyHighlight d-flex justify-content-between">
                                        <div className="colls">
                                          <p>‘25-’34 CAGR</p>
                                          <span
                                            className={
                                              value?.values?.volume_10y24?.[
                                                "25-34 CAGR"
                                              ] !== undefined &&
                                                value?.values?.volume_10y24?.[
                                                "25-34 CAGR"
                                                ] > 0
                                                ? "positive"
                                                : "negative"
                                            }
                                          >
                                            {value?.values?.volume_10y24?.[
                                              "25-34 CAGR"
                                            ] !== undefined
                                              ? value.values.volume_10y24[
                                                "25-34 CAGR"
                                              ] > 0
                                                ? `+${value.values.volume_10y24["25-34 CAGR"]}%`
                                                : `${value.values.volume_10y24["25-34 CAGR"]}%`
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <h2 className="ChartTitle mb-3 mt-4">
                            10YP’ 25 Volume and Total PCC
                          </h2>
                          <div className="chartWrapper mb3">
                            <div className="row">
                              <div className="col-12">
                                <DualAxisChart
                                  dualaxischartData={dualaxischartData}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div
                                className="dtBottom"
                                style={{
                                  justifyContent: "end",
                                }}
                              >
                                <p>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "30px", // Length of the line
                                      height: "4px", // Thickness of the line
                                      backgroundColor: "white",
                                      marginRight: "8px",
                                      border: "1px solid black", // Makes white visible on light backgrounds
                                    }}
                                  ></span>
                                  Volume
                                </p>
                                <p>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "30px",
                                      height: "4px",
                                      backgroundColor: "transparent", // Transparent to show dashed border
                                      marginRight: "8px",
                                      borderTop: "2px dashed yellow", // Dashed yellow line
                                    }}
                                  ></span>
                                  Total PCC
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <h2 className="ChartTitle mb-3 mt-4">
                                Beer Price Tier Evolution
                              </h2>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                              <div
                                className="topToggle"
                                style={{
                                  justifyContent: "end",
                                }}
                              >
                                <p>Segment Volume</p>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    defaultChecked
                                    onChange={(e) =>
                                      setShowSegmentChart(e.target.checked)
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <p>Segment Weight</p>
                              </div>
                            </div>
                          </div>

                          <div
                            className="dtBottom"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end", // Aligns to the right
                              alignItems: "center", // Aligns items vertically
                              gap: "20px", // Space between legend items
                              paddingRight: "20px", // Ensures some space from the right edge
                              marginBottom: "10px", // Moves legend slightly upwards
                              position: "relative",
                              top: "10px", // Adjust this value to fine-tune the upward shift
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <span
                                className="lwhite"
                                style={{
                                  width: "12px",
                                  height: "12px",
                                  backgroundColor: "#A1E2C1", // Light green
                                  display: "inline-block",
                                  borderRadius: "3px",
                                }}
                              ></span>
                              <p
                                style={{
                                  margin: 0,
                                  color: "#ffffff",
                                  fontSize: "14px",
                                }}
                              >
                                P/SP
                              </p>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <span
                                className="lYellow"
                                style={{
                                  width: "12px",
                                  height: "12px",
                                  backgroundColor: "#FFD700", // Yellow
                                  display: "inline-block",
                                  borderRadius: "3px",
                                }}
                              ></span>
                              <p
                                style={{
                                  margin: 0,
                                  color: "#ffffff",
                                  fontSize: "14px",
                                }}
                              >
                                Core +
                              </p>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <span
                                className="lDotedGrey"
                                style={{
                                  width: "12px",
                                  height: "12px",
                                  backgroundColor: "#808080", // Grey
                                  display: "inline-block",
                                  borderRadius: "3px",
                                }}
                              ></span>
                              <p
                                style={{
                                  margin: 0,
                                  color: "#ffffff",
                                  fontSize: "14px",
                                }}
                              >
                                Mainstream
                              </p>
                            </div>
                          </div>

                          {showSegmentChart ? (
                            <div className="chartWrapper mb3">
                              <div className="row">
                                <div className="col-12">
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      margin: "10px 0",
                                    }}
                                  >
                                    10YP’25
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <StackedBarChart
                                    Stackedchart={Stackedchart}
                                  />
                                </div>
                                <div className="col-md-5">
                                  <div className="tableStyle2">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th colSpan={3}>Delta pp </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td style={{ fontWeight: "600" }}>
                                            2015-24
                                          </td>
                                          <td style={{ fontWeight: "600" }}>
                                            2025-34
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            P / SP
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {cagrValues_2.premium_2015_2024_sw}
                                            pp
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {cagrValues_2.premium_2025_2034_sw}
                                            pp
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#211e00",
                                              color: " #F5E003",
                                            }}
                                          >
                                            Core+
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues_2.core_2015_2024_sw}pp
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues_2.core_2025_2034_sw}pp
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Mainstream​​</td>
                                          <td style={{ color: " #737373" }}>
                                            {
                                              cagrValues_2.mainstream_2015_2024_sw
                                            }
                                            pp
                                            {/* mainstream_2015_2024 */}
                                          </td>
                                          <td style={{ color: " #737373" }}>
                                            {
                                              cagrValues_2.mainstream_2025_2034_sw
                                            }
                                            pp
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      margin: "10px 0",
                                    }}
                                  >
                                    10YP’24
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <StackedBarChart2
                                    Stackedchart2={Stackedchart2}
                                  />
                                </div>
                                <div className="col-md-5">
                                  <div className="tableStyle2">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th colSpan={3}>Delta pp </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td style={{ fontWeight: "600" }}>
                                            2015-24
                                          </td>
                                          <td style={{ fontWeight: "600" }}>
                                            2025-34
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            P / SP
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {
                                              cagrValues_2.premium_2015_2024_2_sw
                                            }
                                            pp
                                            {/* premium_2015_2024_2 */}
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {
                                              cagrValues_2.premium_2025_2034_2_sw
                                            }
                                            pp
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#211e00",
                                              color: " #F5E003",
                                            }}
                                          >
                                            Core+
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues_2.core_2015_2024_2_sw}pp
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues_2.core_2025_2034_2_sw}pp
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Mainstream​​</td>
                                          <td style={{ color: " #737373" }}>
                                            {
                                              cagrValues_2.mainstream_2015_2024_2_sw
                                            }
                                            pp
                                          </td>
                                          <td style={{ color: " #737373" }}>
                                            {
                                              cagrValues_2.mainstream_2025_2034_2_sw
                                            }
                                            pp
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="chartWrapper mb3">
                              <div className="row">
                                <div className="col-12">
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      margin: "10px 0",
                                    }}
                                  >
                                    10YP’25
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <StackedBarChart3
                                    Stackedchart3={Stackedchart3}
                                  />
                                </div>
                                <div className="col-md-5">
                                  <div className="tableStyle2">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th colSpan={3}>Volume CAGR</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td style={{ fontWeight: "600" }}>
                                            2015-24
                                          </td>
                                          <td style={{ fontWeight: "600" }}>
                                            2025-34
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            P / SP
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {cagrValues.premium_2015_2024}%
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {cagrValues.premium_2025_2034}%
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#211e00",
                                              color: " #F5E003",
                                            }}
                                          >
                                            Core+
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues.core_2015_2024}%
                                            {/* core_2015_2024_sw */}
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues.core_2025_2034}%
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Mainstream​​</td>
                                          <td style={{ color: " #737373" }}>
                                            {cagrValues.mainstream_2015_2024}%
                                          </td>
                                          <td style={{ color: " #737373" }}>
                                            {cagrValues.mainstream_2025_2034}%
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      margin: "10px 0",
                                    }}
                                  >
                                    10YP’24
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <StackedBarChart4
                                    Stackedchart4={Stackedchart4}
                                  />
                                </div>
                                <div className="col-md-5">
                                  <div className="tableStyle2">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th colSpan={3}>Volume CAGR</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td style={{ fontWeight: "600" }}>
                                            2015-24
                                          </td>
                                          <td style={{ fontWeight: "600" }}>
                                            2025-34
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            P / SP
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {cagrValues.premium_2015_2024_2}%
                                            {/*  {cagrValues.core_2015_2024}% */}
                                            {/* premium_2015_2024_2 */}
                                          </td>
                                          <td
                                            style={{
                                              background: "#131a15",
                                              color: " #95CBA5",
                                            }}
                                          >
                                            {cagrValues.premium_2025_2034_2}%
                                            {/* premium_2025_2034_2 */}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#211e00",
                                              color: " #F5E003",
                                            }}
                                          >
                                            Core+
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues.core_2015_2024_2}%
                                            {/* core_2015_2024_2 */}
                                          </td>
                                          <td style={{ color: " #F5E003" }}>
                                            {cagrValues.core_2025_2034_2}%
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Mainstream​​</td>
                                          <td style={{ color: " #737373" }}>
                                            {cagrValues.mainstream_2015_2024_2}%
                                          </td>
                                          <td style={{ color: " #737373" }}>
                                            {cagrValues.mainstream_2025_2034_2}%
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* segement volume */}
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === "IndustryOutlook" && (
                    <div id="IndustryOutlook">
                      <div className="row">
                        <div className="col-12">
                          <h2 className="ChartTitle mb-3">SOT Evolution</h2>
                        </div>

                        <div className="chartWrapper mb3 "style={{ marginLeft: "10px" , width:"98%"}}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "",
                              gap: "0px",
                              marginTop: "20px",
                            }}
                          >
                            <div className="col-2">
                              <p
                                style={{
                                  display: "flex",
                                  flexDirection: "column", // Align items vertically
                                  justifyContent: "center",
                                  alignItems: "flex-start", // Align text/icons to the left
                                  gap: "15px", // Space between legend items
                                  fontSize: "16px",
                                  color: "white",
                                  marginLeft: "25px",
                                  marginTop: "65px", // Moves legends a little down
                                  marginBottom: "2px",
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <span
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      borderRadius: "50%",
                                      backgroundColor: "#95cba5",
                                      display: "inline-block",
                                    }}
                                  ></span>
                                  Beer
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <span
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      borderRadius: "50%",
                                      backgroundColor: "#c86d67",
                                      display: "inline-block",
                                    }}
                                  ></span>
                                  Beyond Beer
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <span
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      borderRadius: "50%",
                                      backgroundColor: "#f9f3d3",
                                      display: "inline-block",
                                    }}
                                  ></span>
                                  Wine
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <span
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      borderRadius: "50%",
                                      backgroundColor: "#e4c233",
                                      display: "inline-block",
                                    }}
                                  ></span>
                                  Spirits
                                </span>
                              </p>
                            </div>

                            {circlechartData.length > 0 ? (
                              circlechartData.map((item: ChartData) => (
                                <DonutChart
                                  key={item.year}
                                  year={item.year}
                                  totalAlcohol={item.totalAlcohol}
                                  data={item.data}
                                />
                              ))
                            ) : (
                              <div>Loading charts...</div>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <h2 className="ChartTitle mb-3 mt-3">
                            Volume Evolution
                          </h2>
                        </div>

                        <div className="col-12">
                          <div className="chartWrapper mb3">
                            <div className="row">
                              <div className="col-12">
                                <VolEvolution volevolchart={volevolchart} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <h2 className="ChartTitle mb-3 mt-3">
                            Other Category CAGRs
                          </h2>
                          <div className="tableStyle3">
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  {/* <th>2009-19</th> */}
                                  <th>2015-19</th>
                                  <th>2015-25</th>
                                  <th>2025-35</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Beer</td>
                                  {/* <td>{volcagr.Beer?.["2009_2019"]}%</td> */}
                                  <td>{volcagr.Beer?.["2015_2019"]}%</td>
                                  <td>{volcagr.Beer?.["2015_2025"]}%</td>
                                  <td>{volcagr.Beer?.["2025_2035"]}%</td>
                                </tr>
                                <tr>
                                  <td>Beyond Beer</td>
                                  {/* <td>
                                  {volcagr["Beyond Beer"]?.["2009_2019"]}%
                                </td> */}
                                  <td>
                                    {volcagr["Beyond Beer"]?.["2015_2019"]}%
                                  </td>
                                  <td>
                                    {volcagr["Beyond Beer"]?.["2015_2025"]}%
                                  </td>
                                  <td>
                                    {volcagr["Beyond Beer"]?.["2025_2035"]}%
                                  </td>
                                </tr>
                                <tr>
                                  <td>Beer + Beyond Beer</td>
                                  {/* <td>
                                  {volcagr.BeerBeyondBeer?.["2009_2019"]}%
                                </td> */}
                                  <td>
                                    {volcagr.BeerBeyondBeer?.["2015_2019"]}%
                                  </td>
                                  <td>
                                    {volcagr.BeerBeyondBeer?.["2015_2025"]}%
                                  </td>
                                  <td>
                                    {volcagr.BeerBeyondBeer?.["2025_2035"]}%
                                  </td>
                                </tr>
                                <tr>
                                  <td>Wine</td>
                                  {/* <td>{volcagr.Wine?.["2009_2019"]}%</td> */}
                                  <td>{volcagr.Wine?.["2015_2019"]}%</td>
                                  <td>{volcagr.Wine?.["2015_2025"]}%</td>
                                  <td>{volcagr.Wine?.["2025_2035"]}%</td>
                                </tr>
                                <tr>
                                  <td>Spirits</td>
                                  {/* <td>{volcagr.Spirits?.["2009_2019"]}%</td> */}
                                  <td>{volcagr.Spirits?.["2015_2019"]}%</td>
                                  <td>{volcagr.Spirits?.["2015_2025"]}%</td>
                                  <td>{volcagr.Spirits?.["2025_2035"]}%</td>
                                </tr>
                                <tr>
                                  <td>Total Alcohol</td>
                                  {/* <td>
                                  {volcagr["Total Alcohol"]?.["2009_2019"]}%
                                </td> */}
                                  <td>
                                    {volcagr["Total Alcohol"]?.["2015_2019"]}%
                                  </td>
                                  <td>
                                    {volcagr["Total Alcohol"]?.["2015_2025"]}%
                                  </td>
                                  <td>
                                    {volcagr["Total Alcohol"]?.["2025_2035"]}%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
