import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, YpmsalConfig } from "./authConfig";

const getRedirectUri = () => {
  if (
    window.location.hostname.toLowerCase() ===
    process.env.REACT_APP_DEV_10YP_HOSTNAME
  ) {
    return YpmsalConfig; // Redirect for auth flow
  }
  return msalConfig; // Redirect for dashboard flow
};

const msalInstance = new PublicClientApplication(getRedirectUri());

msalInstance.initialize().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  );

  // Start measuring performance in your app
  reportWebVitals();
});
