import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import AbLogo from "../../assets/images/abiLogo.png";
import Loader from "../../components/loader/loader";
import RaiseRequest from "./raise_request";
import DynamicHead from "../../components/DynamicHead";

const YpLogin: React.FC = () => {
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleLogin = (source: string) => {
    localStorage.setItem("loginSource", source);
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error("Login error:", e);
    });
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      <DynamicHead title="10YP Login" favicon="/logo-10.png" />
      <div className="topLine"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="loginDivWrapper">
              <div className="loginDiv">
                <h2 className="mb-2">Industry 10YP</h2>
                <div className="line"></div>
                <p className="mb-4">
                  Where Data-Driven Insights illuminate your path to informed
                  decisions.
                </p>
                <button
                  className="loginButton"
                  onClick={() => handleLogin("10YP")}
                >
                  Login
                </button>
                <div className="bootomLine"></div>
              </div>
              <p>
                If you don’t have access, please{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleLogin("10YP")}
                >
                  Raise a Request
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pageFooter">
        <div className="row">
          <div className="col-md-12">
            <img src={AbLogo} alt="AB Logo" />
            <hr />
            <p>© 2024 Anheuser-Busch InBev All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YpLogin;
